import React, { FunctionComponent } from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { Underline } from '@bunac/components/Programme/Decoration/Underline';
import { Faq } from '@bunac/components/Programme/FAQ';
import { MarkdownRender } from '../MarkdownRender';

interface GridProps {
  title: string;
  subtitle?: string;
  items: Faq[];
}

interface GridItemProps {
  item: Faq;
  backgroundColor: 'bg-transparent' | 'bg-grey-light';
}

const GridItem: FunctionComponent<GridItemProps> = ({
  item,
  backgroundColor,
}) => (
  <div
    className={`text-center ${backgroundColor} py-4 px-8 lg:grid lg:grid-cols-2 lg:text-left lg:max-w-4xl lg:px-20`}
  >
    <div className="font-worksans font-semibold text-sm mb-4 lg:text-left">
      {item.question}
    </div>
    <div className="font-worksans">
      {item.answer && (
        <MarkdownRender
          className={'editor-text-vanilla'}
          content={item.answer}
        />
      )}
    </div>
  </div>
);

const Grid: FunctionComponent<GridProps> = ({ title, subtitle, items }) => (
  <div className="flex flex-col items-center py-8">
    {title && (
      <ContentItemTitle title={title} subtitle={subtitle} bottomMargin={2} />
    )}
    {title && <Underline className="mb-4" />}
    <div>
      {items.map((f: Faq, i) => (
        <GridItem
          key={i}
          item={f}
          backgroundColor={i % 2 === 0 ? 'bg-transparent' : 'bg-grey-light'}
        />
      ))}
    </div>
  </div>
);

export { Grid };
