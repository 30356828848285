import React, { FunctionComponent } from 'react';
import { IconContent } from '@bunac/components/Programme/Icon';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import { Image } from '@bunac/components/Programme/Image';

interface IconGridProps {
  icons: IconContent[];
}

interface IconProp {
  icon: IconContent;
}

const IconGrid: FunctionComponent<IconGridProps> = ({ icons }) => (
  <div className="lg:flex lg:flex-col items-center mx-auto">
    {icons.length <= 4 ? (
      <div className={`flex flex-col space-y-4 md:mb-4 md:max-w-5xl md:grid md:grid-cols-${icons.length} md:gap-8 md:space-y-0`}>
        {icons.map((icon, i) => (
          <IconView icon={icon} key={i} />
        ))}
      </div>
    ) : (
      <div className="flex flex-col space-y-4 md:mb-4 md:max-w-5xl md:grid md:grid-cols-4 md:gap-8 md:space-y-0">
        {icons.map((icon, i) => (
          <IconView icon={icon} key={i} />
        ))}
      </div>
    )}
  </div>
);

const IconView: FunctionComponent<IconProp> = ({ icon }) => (
  <div className="text-center md:w-48">
    <img src={icon.url} alt="Icon" className="block mx-auto mb-4" />
    {icon.content && (
      <MarkdownRender content={icon.content} className="md:w-48" />
    )}
  </div>
);

export { IconGrid };
