import { ButtonDataSource } from '@bunac/components/Page/datasource';
import React, { FunctionComponent } from 'react';
import { Underline, UNDERLINE_COLOR } from '../Decoration/Underline';
import { LinkButton } from '../LinkButton';
import { MarkdownRender } from '../MarkdownRender';
import { ContentItemTitle } from './ContentItemTitle';

interface TextProps {
  title: string;
  subtitle: string;
  content: string;
  buttons?: ButtonDataSource[];
}

const TextWithButtons: FunctionComponent<TextProps> = ({
  title,
  subtitle,
  content,
  buttons,
}) => (
  <div className="py-12 px-6 flex flex-col items-center">
    <ContentItemTitle
      title={title}
      subtitle={subtitle}
      titleColor={'navy'}
      subtitleColor={'blue'}
      bottomMargin={0}
      inverted={true}
    />
    <Underline color={UNDERLINE_COLOR.blue} className={'mb-8'} />
    <div className="text-center lg:max-w-2xl mb-8">
      <MarkdownRender content={content} />
    </div>
    {buttons && (
      <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
        {buttons
          .filter((b: ButtonDataSource) => b.Text && b.URL)
          .map((button: ButtonDataSource, i: number) => (
            <LinkButton
              key={i}
              cta={button.Text}
              url={button.URL}
              color={'pink'}
            />
          ))}
      </div>
    )}
  </div>
);

export { TextWithButtons };
