import React, { FunctionComponent } from 'react';
import { ContentItem } from '@bunac/components/Programme/ContentItem';
import { ButtonContent } from '@bunac/components/Programme/Button';
import {
  SideBySideBlock,
  SideBySideBlockAlternative,
} from '@bunac/components/Programme/Modules/SideBySideBlock';
import { SideBySideBanner } from '@bunac/components/Programme/Modules/SideBySideBanner';
import { SideBySideBasic } from '@bunac/components/Programme/Modules/SideBySideBasic';

export enum ContentDisplay {
  BLOCK = 'Block',
  BASIC = 'Basic',
  BANNER = 'Banner',
  ALT = 'Block_Alternative',
}

interface SideBySideProps {
  title?: string;
  subtitle?: string;
  introduction?: string;
  item1: ContentItem;
  item2: ContentItem;
  button?: ButtonContent;
  contentDisplay?: ContentDisplay;
}

const SideBySide: FunctionComponent<SideBySideProps> = (props) => {
  if (props.contentDisplay === ContentDisplay.BASIC) {
    return <SideBySideBasic {...props} />;
  }
  if (props.contentDisplay === ContentDisplay.BANNER) {
    return <SideBySideBanner {...props} />;
  }

  if (props.contentDisplay === ContentDisplay.ALT) {
    return <SideBySideBlockAlternative {...props} />;
  }
  return <SideBySideBlock {...props} />;
};

export { SideBySide };
