import React from 'react';
import Link from '../common/Link';
import styled from 'styled-components';
import Button from '../Teaser/Button';
import MarkdownIt from 'markdown-it';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Markdown = new MarkdownIt({ html: true });

const MAX_TEXT_LENGTH = 130;

const Item = ({
  key,
  link,
  linkText,
  title,
  thumbnail,
  description,
  ...props
}) => {
  const text = Markdown.render(description || '&nbsp;');
  const textToShow =
    text.length > MAX_TEXT_LENGTH
      ? `${text.slice(0, MAX_TEXT_LENGTH)}...`
      : text;
  const image = getImage(thumbnail);

  return (
    <Wrapper key={key}>
      <Content {...props}>
        <Header to={link || '/'}>
          {image && (<GatsbyImage image={image} alt={title} />)}
          {image === undefined && (<Image alt={title} src={'/images/destinations.jpg'} loading='lazy' decoding='async' />) }
        </Header>
        <InnerContent>
          <Title to={link || '/'}>{title}</Title>
          <Description dangerouslySetInnerHTML={{ __html: textToShow }} />
          <Button to={link || '/'} {...props}>
            {linkText || 'Read More'}
          </Button>
        </InnerContent>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  color: #222;
  margin: 0 16px 16px 0px;
`;
const Title = styled(Link)`
  display: block;
  width: 100%;
  text-decoration: none;
  font-weight: 500;
  color: #222;
  font-size: 22px;
  margin-bottom: 16px;
  &:hover {
    color: #0094a5 !important;
  }
  &:link,
  &:visited {
    color: #222;
  }
`;

const Content = styled.div`
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05);
  background: #fff;
  color: #222;

  &.theme-usa {
    background-color: #08599b;
    color: #fff;

    ${Title} {
      &:hover {
        color: #fff !important;
      }
      &:link,
      &:visited {
        color: #fff;
      }
    }
  }
`;

const Header = styled(Link)``;

const Image = styled.img`
  width: 100%;
  display: block;
  max-height: 230px;
  height: 230px;
  margin-bottom: 0px;
`;

const InnerContent = styled.div`
  padding: 16px;
  min-height: 240px;
`;

const Description = styled.div`
  width: 100%;
  overflow: hidden;
  color: inherit;
  margin-bottom: 16px;
  min-height: 120px;
`;

export default Item;
