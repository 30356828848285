import React, { FunctionComponent } from 'react';
import { ContentItem } from '@bunac/components/Programme/ContentItem';
import { ButtonContent } from '@bunac/components/Programme/Button';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { Underline } from '@bunac/components/Programme/Decoration/Underline';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import { Image } from '@bunac/components/Programme/Image';

interface ItemStyles {
  titleColor: 'blue' | 'navy' | 'white';
  subtitleColor: 'blue' | 'navy' | 'white';
  backgroundColor: 'bg-grey-light' | 'bg-yellow' | 'bg-navy' | 'bg-blue';
  buttonColor: 'pink' | 'blue' | 'navy';
  textColor?: 'text-white';
}

interface SideBySideBaseProps {
  title?: string;
  subtitle?: string;
  introduction?: string;
  item1: ContentItem;
  item2: ContentItem;
  items1Styles: ItemStyles;
  items2styles: ItemStyles;
  button?: ButtonContent;
}

interface SideBySideProps {
  title?: string;
  subtitle?: string;
  introduction?: string;
  item1: ContentItem;
  item2: ContentItem;
  button?: ButtonContent;
}

interface ContentItemViewProps {
  item: ContentItem;
  styles: ItemStyles;
}

const ContentItemView: FunctionComponent<ContentItemViewProps> = ({
  item,
  styles,
}) => (
  <div
    className={`flex flex-1 flex-col lg:max-w-md ${item.image ? '' : 'mb-8'}`}
  >
    {item.image && (
      <div className="relative ml-6 mr-6 z-50 lg:mx-8">
        <Image
          className="rounded-3xl m-auto"
          image={item.image}
          alt={item.title}
        />
      </div>
    )}
    <div
      className={`relative ${
        item.image ? '-top-24 -mb-16 pt-28' : 'pt-6'
      } rounded-3xl px-4 pb-6  z-1 ${styles.backgroundColor} md:px-8`}
    >
      {item.title && (
        <ContentItemTitle
          title={item.title}
          subtitle={item.subtitle}
          bottomMargin={2}
          titleColor={styles.titleColor}
          subtitleColor={styles.subtitleColor}
          inverted={true}
        />
      )}
      <div
        className={`text-center font-worksans text-sm mb-8 lg:px-4 ${
          styles.textColor ? styles.textColor : ''
        }`}
      >
        <MarkdownRender content={item.text} />
      </div>
      {item.button && item.button.url && (
        <div className="flex justify-center mt-3 mb-4">
          <LinkButton
            cta={item.button.text}
            url={item.button.url}
            color={styles.buttonColor}
          />
        </div>
      )}
    </div>
  </div>
);

const SideBySideBlockBase: FunctionComponent<SideBySideBaseProps> = ({
  title,
  subtitle,
  introduction,
  item1,
  item2,
  items1Styles,
  items2styles,
  button,
}) => (
  <div className="py-12 px-4 lg:pt-0 lg:pb-32 md:pb-40 md:flex md:flex-col md:items-center md:justify-center">
    {title && (
      <ContentItemTitle
        title={title}
        subtitle={subtitle}
        bottomMargin={2}
        titleColor="blue"
        subtitleColor="navy"
      />
    )}
    {title && (
      <div className="flex justify-center">
        <Underline />
      </div>
    )}
    {introduction && (
      <MarkdownRender
        content={introduction}
        className="text-center text-sm mt-4 mb-8 max-w-2xl"
      />
    )}
    <div className="md:flex md:flex-row">
      <ContentItemView item={item1} styles={items1Styles} />
      <div className="flex-1 md:relative md:top-20 md:ml-10">
        <ContentItemView item={item2} styles={items2styles} />
      </div>
    </div>
    {button && button.text && button.url && (
      <div className="">
        <LinkButton cta={button.text} url={button.url} color={'blue'} />
      </div>
    )}
  </div>
);

const SideBySideBlock: FunctionComponent<SideBySideProps> = ({
  title,
  subtitle,
  introduction,
  item1,
  item2,
  button,
}) => (
  <SideBySideBlockBase
    title={title}
    subtitle={subtitle}
    introduction={introduction}
    item1={item1}
    items1Styles={{
      titleColor: 'navy',
      subtitleColor: 'blue',
      backgroundColor: 'bg-grey-light',
      buttonColor: 'blue',
    }}
    item2={item2}
    items2styles={{
      titleColor: 'navy',
      subtitleColor: 'navy',
      backgroundColor: 'bg-yellow',
      buttonColor: 'blue',
    }}
    button={button}
  />
);

const SideBySideBlockAlternative: FunctionComponent<SideBySideProps> = ({
  title,
  subtitle,
  introduction,
  item1,
  item2,
  button,
}) => (
  <SideBySideBlockBase
    title={title}
    subtitle={subtitle}
    introduction={introduction}
    item1={item1}
    items1Styles={{
      titleColor: 'white',
      subtitleColor: 'navy',
      backgroundColor: 'bg-blue',
      buttonColor: 'pink',
      textColor: 'text-white',
    }}
    item2={item2}
    items2styles={{
      titleColor: 'navy',
      subtitleColor: 'navy',
      backgroundColor: 'bg-yellow',
      buttonColor: 'blue',
    }}
    button={button}
  />
);

export { SideBySideBlock, SideBySideBlockAlternative };
