import React, { FunctionComponent, ReactNode } from 'react';
import Carousel, {
  RenderArrowProps,
  RenderPaginationProps,
} from 'react-elastic-carousel';

const NextArrow = () => (
  <svg width="17.403" height="31" viewBox="0 0 17.403 31" className="ml-6">
    <path
      id="Path_54"
      data-name="Path 54"
      d="M16.846,14.108,3.295.557a1.961,1.961,0,0,0-2.738,0,1.961,1.961,0,0,0,0,2.738L12.762,15.5.557,27.705a1.961,1.961,0,0,0,0,2.738,1.961,1.961,0,0,0,2.738,0l13.551-13.6A1.961,1.961,0,0,0,16.846,14.108Z"
      fill="#5fbec2"
      fillRule="evenodd"
    />
  </svg>
);

const PreviousArrow = () => (
  <svg width="17.403" height="31" viewBox="0 0 17.403 31" className="mr-6">
    <path
      id="Path_54"
      data-name="Path 54"
      d="M.557,14.108,14.108.557a1.961,1.961,0,0,1,2.738,0,1.961,1.961,0,0,1,0,2.738L4.641,15.5,16.846,27.706a1.936,1.936,0,1,1-2.738,2.738L.557,16.846A1.961,1.961,0,0,1,.557,14.108Z"
      fill="#5fbec2"
      fillRule="evenodd"
    />
  </svg>
);

const InactiveDot = () => (
  <svg width="10" height="10" viewBox="0 0 10 10">
    <g fill="none">
      <path
        d="M5,0A5.206,5.206,0,0,1,8.055,1.042,4.848,4.848,0,0,1,10,5,5,5,0,1,1,5,0Z"
        stroke="none"
      />
      <path
        d="M 5 1 C 2.794390201568604 1 1 2.794390201568604 1 5 C 1 7.205610275268555 2.794390201568604 9 5 9 C 7.205610275268555 9 9 7.205610275268555 9 5 C 9 3.539080142974854 8.222729682922363 2.355370044708252 7.500550270080566 1.87378978729248 L 7.487959861755371 1.865260124206543 C 6.619489669799805 1.266860008239746 5.852180004119873 1 5 1 M 5 0 C 6.038300037384033 0 7.002670288085938 0.3164796829223633 8.055350303649902 1.041810035705566 C 9.128350257873535 1.757329940795898 10 3.276869773864746 10 5 C 10 7.761420249938965 7.761420249938965 10 5 10 C 2.238580226898193 10 0 7.761420249938965 0 5 C 0 2.238580226898193 2.238580226898193 0 5 0 Z"
        stroke="none"
        fill="#ff808b"
      />
    </g>
  </svg>
);

const ActiveDot = () => (
  <svg width="10" height="10" viewBox="0 0 10 10">
    <g fill="#ff808b" stroke="#ff808b" strokeWidth="1">
      <circle cx="5" cy="5" r="5" stroke="none" />
      <circle cx="5" cy="5" r="4.5" fill="none" />
    </g>
  </svg>
);

interface DotProps {
  isActive: boolean;
  onClick: VoidFunction;
}

const Dot: FunctionComponent<DotProps> = ({ isActive, onClick }) => (
  <div className="mr-1" onClick={onClick}>
    {isActive ? <ActiveDot /> : <InactiveDot />}
  </div>
);

const renderQuoteArrows = ({ type, onClick, isEdge }: RenderArrowProps) => {
  const pointer = type === 'PREV' ? <PreviousArrow /> : <NextArrow />;
  return (
    <button
      onClick={onClick}
      disabled={isEdge}
      className={`hidden ${isEdge ? 'opacity-50 cursor-default' : ''} md:block`}
    >
      {pointer}
    </button>
  );
};

const renderPagination = ({
  pages,
  activePage,
  onClick,
}: RenderPaginationProps) => {
  return (
    <div className="flex flex-row">
      {pages.map((page) => {
        return (
          <Dot
            key={page}
            isActive={activePage === page}
            onClick={() => onClick(`${page}`)}
          />
        );
      })}
    </div>
  );
};

interface ContentCarouselProps {
  itemsToShow?: number;
  children: ReactNode;
}

const ContentCarousel: FunctionComponent<ContentCarouselProps> = ({
  itemsToShow = 1,
  children,
}) => (
  <Carousel
    itemsToShow={itemsToShow}
    isRTL={false}
    renderArrow={renderQuoteArrows}
    renderPagination={renderPagination}
  >
    {children}
  </Carousel>
);

export { ContentCarousel };
