import { ButtonDataSource } from '@bunac/components/Page/datasource';
import React, { FunctionComponent, ReactNode } from 'react';
import { RightBurst, LeftBurst } from '../Decoration/Burst';
import { Underline, UNDERLINE_COLOR } from '../Decoration/Underline';
import { LinkButton } from '../LinkButton';
import { MarkdownRender } from '../MarkdownRender';
import { ContentItemTitle } from './ContentItemTitle';

interface TextProps {
  title: string;
  subtitle: string;
  content: string;
  button?: ButtonDataSource;
  decoration?: 'None' | 'Burst';
}

const TextContent: FunctionComponent<TextProps> = ({
  title,
  subtitle,
  content,
  button,
}) => (
  <div className="py-6 md:py-12 px-6 flex flex-col items-center">
    <ContentItemTitle
      title={title}
      subtitle={subtitle}
      titleColor={subtitle ? 'navy' : 'blue'}
      subtitleColor={'blue'}
      bottomMargin={0}
      inverted={true}
      bold={true}
    />
    {title && subtitle && (
      <Underline color={UNDERLINE_COLOR.blue} className={'mb-8'} />
    )}
    <div className="text-center w-full lg:max-w-2xl overflow-x-scroll md:overflow-x-visible">
      <MarkdownRender content={content} />
    </div>
    {button && button?.Text && button?.URL && (
      <div className='mt-8'>
        <LinkButton cta={button.Text} url={button.URL} color={'pink'} />
      </div>
    )}
  </div>
);

interface BurstProps {
  children: ReactNode;
}

const Burst: FunctionComponent<BurstProps> = ({ children }) => (
  <div className="relative lg:max-w-3xl lg:m-auto">
    <RightBurst />
    <LeftBurst />
    {children}
  </div>
);

const Text: FunctionComponent<TextProps> = (props) =>
  props.decoration === 'Burst' ? (
    <Burst>
      <TextContent {...props} />
    </Burst>
  ) : (
    <TextContent {...props} />
  );

export { Text };
