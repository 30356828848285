import React, { FunctionComponent } from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { ButtonDataSource } from '@bunac/components/Page/datasource';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Image } from '../Image';

interface ColourAdBlockThumbnailProps {
  title: string;
  subtitle: string;
  content?: string;
  button?: ButtonDataSource;
  image?: ImageDataLike;
  color: 'yellow' | 'blue' | 'navy' | 'pink' | 'transparent' | null;
  imageLayout: 'full' | 'thumbnail' | 'small' | 'none';
}

const ThumbnailColourAdBlock: FunctionComponent<ColourAdBlockThumbnailProps> =
  ({ title, subtitle, content, button, image, color }) => (
    <div className="px-4 lg:pb-36 lg:flex lg:flex-row-reverse lg:items-start lg:relative lg:mx-6">
      <div
        className={`
        relative flex-grow-0 mx-4 z-50
        lg:top-12 lg:left-4 lg:flex-initial lg:max-w-md
        2lg:-left-8 2lg:top-8
        xl:-left-16
      `}
      >
        <Image
          className="m-auto rounded-3xl filter drop-shadow-images"
          image={image}
          alt={title}
        />
      </div>
      <div
        className={`
        flex flex-col flex-grow items-center relative -z-10 -top-28 pt-32 rounded-3xl bg-${color} p-8
        lg:top-0 lg:p-8 lg:pr-64 lg:absolute lg:max-w-2xl lg:left-12
        2lg:pr-40 2lg:max-w-3xl
        xl:pr-72 xl:ml-24 xl:pt-12
      `}
      >
        <ContentItemTitle
          title={title}
          subtitle={subtitle}
          bottomMargin={2}
          titleColor={'navy'}
        />
        {content && (
          <div className="text-center lg:px-10">
            <MarkdownRender content={content} />
          </div>
        )}
        {button && button?.Text && button?.URL && (
          <div className="flex mt-6 lg:pb-8">
            <LinkButton cta={button.Text} url={button.URL} color="blue" />
          </div>
        )}
      </div>
    </div>
  );

export { ThumbnailColourAdBlock };
