import React, { FunctionComponent } from 'react';
import { ContentCarousel } from '@bunac/components/Programme/ContentCarousel';
import { MarkdownRender } from '../MarkdownRender';
import { Quote } from '../Quote';
import { ContentItemTitle } from './ContentItemTitle';

interface QuotesProps {
  title?: string;
  subtitle?: string;
  quotes: Quote[];
}

interface QuoteItemProps {
  quote: Quote;
}

const Symbol = () => (
  <svg
    className="mx-auto mb-8"
    width="39.404"
    height="38.057"
    viewBox="0 0 39.404 38.057"
  >
    <path
      id="noun_Quote_608661"
      d="M21.5,43.909H4.9V29.53C4.9,17.667,9.338,11.395,20.688,7l.469,1.237C10.276,12.419,6.223,18.179,6.223,29.53V42.587H21.5Zm0-15.788H12.154l-.043-.6c-.555-7.254,2.347-11.563,10.753-15.83l.6,1.152c-7.6,3.883-10.369,7.723-10.07,13.953H21.5ZM43.3,43.909H24.357V29.53c0-11.862,4.438-18.135,15.83-22.53l.469,1.237C29.734,12.419,25.68,18.179,25.68,29.53V42.587h16.3V28.122H31.654l-.043-.6c-.6-7.254,2.3-11.563,10.71-15.83l.6,1.152c-7.6,3.883-10.369,7.723-10.07,13.953H43.26L43.3,43.909Z"
      transform="translate(-4.4 -6.352)"
      fill="#ff808b"
      stroke="#ff808b"
      strokeWidth="1"
    />
  </svg>
);

const QuoteItem: FunctionComponent<QuoteItemProps> = ({ quote }) => (
  <div className="md:max-w-4xl font-worksans">
    <div className="text-center text-base mb-8">
      <MarkdownRender content={quote.content} />
    </div>
    <div className="text-blue text-center font-semibold uppercase">
      {quote.person}
    </div>
    <div className="text-xs text-center mb-8">{quote.experience}</div>
  </div>
);

const Quotes: FunctionComponent<QuotesProps> = ({
  title,
  subtitle,
  quotes,
}) => (
  <div className={`lg:px-12 lg:py-12 lg:max-w-4xl lg:m-auto`}>
    {title && (
      <ContentItemTitle
        title={title}
        subtitle={subtitle}
        bottomMargin={2}
        titleColor={'navy'}
        subtitleColor={'blue'}
        inverted={false}
      />
    )}
    <div className="flex content-center">
      <Symbol />
    </div>
    <ContentCarousel>
      {quotes.map((quote, i) => (
        <QuoteItem key={i} quote={quote} />
      ))}
    </ContentCarousel>
  </div>
);

export { Quotes };
