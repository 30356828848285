import { ContentItem } from '../Programme/ContentItem';
import formatSlug from '@bunac/lib/slugFormatter';
import { ProductGroup } from '../Programme/ProductGroup';
import { ImageDataLike } from 'gatsby-plugin-image';
import { RedirectItem } from '../Programme/RedirectItem';
import { BoxItem } from '../Programme/BoxItem';

export interface ButtonDataSource {
  Text: string;
  URL: string;
}

export interface ImageDataSource {
  publicURL: string;
}

export interface IconDataSource {
  Content: string;
  Key_Visual?: ImageDataSource;
}

export interface ContentItemDataSource {
  Title: string;
  Subtitle?: string;
  Content: string;
  Button?: ButtonDataSource;
  Key_Visual?: ImageDataLike;
}

export interface BoxItemDataSource {
  Box_Title: string;
  Box_Link: string;
  BoxImage: any;
}

export interface RedirectItemDataSource {
  Redirect_Title: string;
  Redirect_Content: string;
  Redirect_Link: string;
  RedirectImage: any;
}

export interface QuoteTabDataSource {
  Tab_Title: string;
  Quotes: QuoteDataSource[];
}

export interface QuoteDataSource {
  Person: string;
  Experience: string;
  Content: string;
}

export interface BlogsDataSource {
  Title: string;
  Intro: string;
  Category: string;
  Key_Visual?: ImageDataLike;
}

export interface BlogCategoryDataSource {
  strapiId: string;
  Title: string;
  Content: string;
  Key_Visual?: ImageDataLike;
}

export interface FaqDataSource {
  Question: string;
  Answer: string;
}

export interface LocationDataSource {
  Camp: string;
  Location: string;
  Content: string;
  Key_Visual?: ImageDataLike;
  Button?: ButtonDataSource;
}

export interface ProductsDataSource {
  strapiId: number;
  Title: string;
  Cost: {
    AUD: number;
    EUR: number;
    GBP: number;
    NZD: number;
    USD: number;
  };
}

export interface ProductGroupProductDataSource {
  Product_Title: string;
  Product: {
    id: number;
  };
}

export interface ProductGroupDataSource {
  Title: string;
  Subtitle: string;
  Introduction?: string;
  First_Payment?: ProductGroupProductDataSource;
  Desposit?: ProductGroupProductDataSource;
  Second_Payment?: ProductGroupProductDataSource;
  Details?: string;
  Final_Text?: string;
  Include_Total: boolean;
  Button: ButtonDataSource;
}

export interface StepsDataSource {
  Title: string;
  Subtitle: string;
  Content: string;
}

export interface BlogItemDataSource {
  strapiId: string;
  Title: string;
  Intro: string;
  Content: string;
  Category: {
    id: string;
    Title: string;
  };
  Key_Visual: ImageDataLike;
  Programmes: {
    id: string;
    Title: string;
    Country: string;
    Category: string;
  };
}

export interface JobRoleDataSource {
  Title: string;
  Location: string;
  Overview: string;
  Key_Visual: ImageDataLike;
  Details: string;
  Outcomes: string;
  Actions: ButtonDataSource[];
}

export const locationMapper = (location: LocationDataSource) => ({
  camp: location.Camp,
  location: location.Location,
  content: location.Content,
  image: location.Key_Visual,
  button: {
    text: location.Button?.Text,
    url: location.Button?.URL,
  },
});

export const contentItemMapper = (
  item: ContentItemDataSource
): ContentItem => ({
  title: item.Title,
  subtitle: item.Subtitle,
  text: item.Content,
  button: {
    text: item.Button?.Text,
    url: item.Button?.URL,
  },
  image: item.Key_Visual,
});

export const boxItemMapper = (
  item: BoxItemDataSource
) : BoxItem => ({
  title: item.Box_Title,
  link: item.Box_Link,
  image: item.BoxImage.publicURL,
});

export const redirectItemMapper = (
  item: RedirectItemDataSource
): RedirectItem => ({
  title: item.Redirect_Title,
  content: item.Redirect_Content,
  link: item.Redirect_Link,
  image: item.RedirectImage.publicURL,
});

export const blogsItemMapper = (
  blogs: BlogsDataSource[],
  categories: BlogCategoryDataSource[]
): any[] =>
  blogs.map((blog: BlogsDataSource) => {
    const category = categories.find((c) => c.strapiId === blog.Category);

    return {
      title: blog.Title,
      introduction: blog.Intro,
      url: formatSlug([
        'blog',
        category ? category.Title : 'other',
        blog.Title,
      ]),
      image: blog.Key_Visual,
    };
  });

export const productDataMapper = (
  data: ProductGroupDataSource,
  products: ProductsDataSource[]
): ProductGroup => ({
  title: data.Title,
  subtitle: data.Subtitle,
  introduction: data.Introduction,
  details: data.Details,
  firstPayment: {
    id: data.First_Payment?.Product.id,
    title: data.First_Payment?.Product_Title,
    cost: products.find((p) => p.strapiId === data.First_Payment?.Product.id)
      ?.Cost,
  },
  secondPayment: {
    id: data.Second_Payment?.Product.id,
    title: data.Second_Payment?.Product_Title,
    cost: products.find((p) => p.strapiId === data.Second_Payment?.Product.id)
      ?.Cost,
  },
  deposit: {
    id: data.Desposit?.Product.id,
    title: data.Desposit?.Product_Title,
    cost: products.find((p) => p.strapiId === data.Desposit?.Product.id)?.Cost,
  },
  finalText: data.Final_Text,
  includeTotal: data.Include_Total,
  button: {
    text: data.Button.Text,
    url: data.Button.URL,
  },
});
