import React, { FunctionComponent } from 'react';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { MarkdownRender } from '../MarkdownRender';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Image } from '../Image';

interface BlogItemProps {
  title: string;
  introduction: string;
  url: string;
  image?: ImageDataLike;
  backgroundColor?: 'white' | 'grey-light';
}

const PlaceHolderImage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="312"
    height="236"
    viewBox="0 0 312 236"
    className="rounded-3xl filter drop-shadow-images blog-items-images"
  >
    <rect
      id="Rectangle_161"
      data-name="Rectangle 161"
      width="312"
      height="236"
      rx="30"
      fill="#D5D2D2"
    />
  </svg>
);

const BlogItem: FunctionComponent<BlogItemProps> = ({
  title,
  introduction,
  url,
  image,
  backgroundColor = 'white',
}) => (
  <div className="flex flex-col">
    <div className="relative flex ml-6 mr-6 z-50">
      {image && (
        <Image
          className="rounded-3xl filter drop-shadow-images object-cover blog-items-images"
          image={image}
          alt={title}
        />
      )}
      {!image && <PlaceHolderImage />}
    </div>
    <div
      className={`relative flex-1 -top-24 -mb-14 bg-${backgroundColor} rounded-3xl p-6 pb-16 pt-28 z-1`}
    >
      <h3 className="flex-none text-xl font-medium text-blue mb-3">{title}</h3>
      {introduction && (
        <MarkdownRender
          content={introduction}
          className="flex-none text-sm font-medium text-black mb-6"
        />
      )}
      <div className="flex absolute bottom-6 mt-3 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
        <LinkButton url={url} color={'pink'} />
      </div>
    </div>
  </div>
);

export { BlogItem };
