import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface BannerProps {
  url?: string;
  img?: any;
}

const Divisor = styled.div`
padding-left: 0px;
padding-right: 0px;
width: calc(100% + 64px);
cursor: pointer;
img {
    height: 60vh;
    width: 100%;
    object-fit: cover;
  }
`;

const Banner: FunctionComponent<BannerProps> = ({
  url,
  img,
}) => (
  <>
    {url && img.publicURL &&
      <Divisor onClick={() => url}>
        <img src={img.publicURL} onClick={() => window.location.assign(url)} className="my-12" style={{ width: 'calc(100% + 64px)', marginLeft: '-32px' }} loading='lazy'></img>
      </Divisor>
    }
  </>
);

export { Banner };
