import React, { FunctionComponent } from 'react';
import { ContentItem } from '@bunac/components/Programme/ContentItem';
import { ContentItemTitle } from './ContentItemTitle';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import {
  LinkButton,
  LinkButtonStyle,
} from '@bunac/components/Programme/LinkButton';
import { Image } from '@bunac/components/Programme/Image';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';

interface AlternateHighlightsProps {
  title: string;
  subtitle?: string;
  introduction?: string;
  items: ContentItem[];
}

interface ContentItemViewProps {
  index: number;
  item: ContentItem;
}

const ContentItemView: FunctionComponent<ContentItemViewProps> = ({
  index,
  item,
}) => {
  const titleColor = 'grey-dark';
  const subtitleColor = 'grey-dark';
  const backgroundColor = 'bg-white';
  const buttonColor = 'pink';
  const order = index % 2 === 0 ? 'lg:order-1' : 'order-0';

  return (
    <div className='py-8 px-6 md:py-12 w-full md:max-w-4xl m-auto z-50'>
      <div className={`grid grid-cols-12 gap-4 flex`}>
        {item.image && (
          <div className={`col-span-12 md:col-span-6 flex flex-col z-50 ${order}`}>
            <Image className="my-auto stacked-image-anternate object-cover" image={item.image} alt={item.title}/>
          </div>
        )}
        <div className={`col-span-12 md:col-span-6 flex flex-col`}>
          <div className='md:mt-8'>
            {item.title && (
              <ContentItemTitle title={item.title}
                subtitle={item.subtitle}
                bottomMargin={2}
                titleColor={item.subtitle ? titleColor : subtitleColor}
                subtitleColor={subtitleColor}
                inverted={false}
                bold={true}
                textAlignment={'text-left'}
                textFont={'font-worksans font-bold lg:px-8 lg:pr-16 text-center md:text-left'}
                titleSize={'text-3xl'}
              />
            )}
            <div className={`font-worksans text-center md:text-left font-light mb-8 lg:mb-0 lg:px-8 lg:pr-16`}>
              <MarkdownRender content={item.text} />
            </div>
            {item.button && item.button.url && (
              <div className="flex justify-center md:justify-start mt-6 mb-4 lg:px-8 lg:pr-16">
                <LinkButton cta={item.button.text} url={item.button.url} color={buttonColor as LinkButtonStyle}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const AlternateHighlights: FunctionComponent<AlternateHighlightsProps> = ({
  title,
  subtitle,
  introduction,
  items,
}) => {

  return (
    <div className="relative flex flex-col items-center md:py-12 md:px-6">
      <ContentItemTitle
        title={title}
        subtitle={subtitle}
        bottomMargin={2}
        titleColor={'navy'}
        subtitleColor={'blue'}
        inverted={true}
      />
      {title && (
        <Underline color={UNDERLINE_COLOR.blue} className={'mb-8 lg:mb-12'} />
      )}
      {introduction && (
        <MarkdownRender
          content={introduction}
          className="font-worksans text-sm text-center mb-8 md:max-w-2xl"
        />
      )}
      <div className="">
        {items.map((item, i) => (
          <ContentItemView
            item={item}
            index={i}
            key={i}
          />
        ))}
      </div>
      <img className='absolute -right-20 -top-44 xl:-top-32'
        src="/images/homepage-blue-right.svg"
        alt="Blue brush stroke positioned on the right side"
      />
      <img className='hidden md:absolute -left-16 top-40 xl:top-64'
        src="/images/homepage-orange-left.svg"
        alt="Orange brush stroke positioned on the left side"
      />
      <img className='hidden xl:inline absolute -left-32 -bottom-20 xl:-bottom-20 rotate-300'
        src="/images/homepage-gray-left.svg"
        alt="Gray brush stroke positioned on the left bottom side"
      />
    </div>
  );
};

export { AlternateHighlights };
