import React, { FunctionComponent } from 'react';

interface PaperLightProps {
  children: React.ReactNode;
}

const PaperLight: FunctionComponent<PaperLightProps> = ({ children }) => (
  <div className="paper-light-top">
    <div className="paper-light-bottom">
      <div className="paper-light">{children}</div>
    </div>
  </div>
);

const PaperLightTop: FunctionComponent<PaperLightProps> = ({ children }) => (
  <div className="paper-light-top top-only">
    <div className="paper-light top-only">{children}</div>
  </div>
);

const PaperLightBottom: FunctionComponent<PaperLightProps> = ({ children }) => (
  <div className="paper-light-bottom bottom-only">
    <div className="paper-light bottom-only">{children}</div>
  </div>
);

const PaperLightHalf: FunctionComponent<PaperLightProps> = ({ children }) => (
  <div className="background-half">
    <div className="paper-light-half" />
    {children}
  </div>
);

export { PaperLight, PaperLightTop, PaperLightBottom, PaperLightHalf };
