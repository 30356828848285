import React from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { Underline } from '@bunac/components/Programme/Decoration/Underline';
import { LinkButton } from '@bunac/components/Programme/LinkButton';

const TravelConfidence = () => (
  <div className="py-12 lg:py-12 flex flex-col items-center">
    <ContentItemTitle
      title={'Travel with'}
      subtitle={'confidence'}
      bottomMargin={2}
      inverted={true}
      titleSize="text-8xl"
      subtitleSize="text-4xl"
    />
    <Underline className="mb-6 md:mb-12" />
    <div className="sm:grid sm:grid-cols-3 sm:gap-8 md:max-w-3xl">
      <div className="flex flex-col items-center">
        <h4 className="font-fave text-6xl mb-2 md:mb-6">
          <span className="text-blue">100</span>
          <span className="text-pink">%</span>
        </h4>
        <p className="text-center text-base font-worksans px-4 md:max-w-xs">
          Your trip will be covered under our Booking Guarantee.
        </p>
      </div>
      <div className="flex flex-col items-center">
        <h4 className="font-fave text-6xl mb-2 md:mb-6">
          <span className="text-blue">24</span>
          <span className="text-pink">/</span>
          <span className="text-blue">7</span>
        </h4>
        <p className="text-center text-base font-worksans px-4 md:max-w-xs">
          24/7 help and support from our partners on the ground in the USA.
        </p>
      </div>
      <div className="flex flex-col items-center">
        <h4 className="font-fave text-6xl mb-2 md:mb-6">
          <span className="text-blue lowercase">Easy</span>
        </h4>
        <p className="text-center text-base font-worksans px-4 md:max-w-xs">
          Secure your placement with a low deposit and pay in instalments.
        </p>
      </div>
    </div>
    <div className="mt-4 mb-12 md:mt-12">
      <LinkButton cta={'Get in touch'} url={'/contact'} color={'navy'} />
    </div>
  </div>
);

export { TravelConfidence };
