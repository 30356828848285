import React, { FunctionComponent } from 'react';
import { ContentItem } from '@bunac/components/Programme/ContentItem';
import { ContentItemTitle } from './ContentItemTitle';
import { ContentCarousel } from '@bunac/components/Programme/ContentCarousel';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';
import { Image } from '@bunac/components/Programme/Image';

interface ThreeUspProps {
  title: string;
  subtitle?: string;
  introduction?: string;
  items: ContentItem[];
}

interface ContentItemProps {
  item: ContentItem;
}

const ContentItemDisplay: FunctionComponent<ContentItemProps> = ({ item }) => (
  <div className="px-4 lg:flex lg:mb-8 lg:items-center">
    <div className="lg:flex-1 lg:mr-12">
      <Image className="rounded-3xl" image={item.image} alt={item.title} />
    </div>
    <div className="lg:flex-1">
      {item.title && (
        <ContentItemTitle
          title={item.title}
          subtitle={item.subtitle}
          subtitleColor={'blue'}
          bottomMargin={4}
          textAlignment={'text-left'}
        />
      )}
      {item.text && (
        <div className="font-worksans text-sm text-left md:max-w-2xl lg:mr-24 mb-8">
          <MarkdownRender content={item.text} />
        </div>
      )}
    </div>
  </div>
);

const ThreeUsp: FunctionComponent<ThreeUspProps> = ({
  title,
  subtitle,
  introduction,
  items,
}) => (
  <div className="py-12">
    <div className="flex flex-col items-center px-4">
      {title && (
        <>
          <ContentItemTitle
            title={title}
            subtitle={subtitle}
            inverted={true}
            titleColor={'navy'}
            subtitleColor={'blue'}
            bottomMargin={0}
          />
          <Underline color={UNDERLINE_COLOR.blue} className={'mb-8'} />
        </>
      )}
      {introduction && (
        <MarkdownRender
          content={introduction}
          className="font-worksans text-sm text-center mb-8 md:max-w-2xl"
        />
      )}
    </div>
    <div className="lg:px-12">
      <ContentCarousel>
        {items.map((item, i) => (
          <ContentItemDisplay item={item} key={i} />
        ))}
      </ContentCarousel>
    </div>
  </div>
);

export { ThreeUsp };
