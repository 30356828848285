import React, { FunctionComponent, useState } from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';
import { Faq } from '@bunac/components/Programme/FAQ';
import { MarkdownRender } from '../MarkdownRender';

interface FAQProps {
  title: string;
  subtitle?: string;
  introduction?: string;
  questions: Faq[];
}

interface FAQItemProps {
  question: Faq;
  initState: boolean;
}

interface IconProps {
  className?: string;
}

const Icon: FunctionComponent<IconProps> = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.706"
    height="16.676"
    viewBox="0 0 29.706 16.676"
    className={className}
  >
    <g id="noun_Arrow_2335663" transform="translate(0 16.676) rotate(-90)">
      <path
        id="Path_54"
        data-name="Path 54"
        d="M16.143,13.519,3.157.534a1.879,1.879,0,0,0-2.624,0,1.879,1.879,0,0,0,0,2.624l11.7,11.7-11.7,11.7a1.879,1.879,0,0,0,0,2.624,1.879,1.879,0,0,0,2.624,0l12.985-13.03A1.879,1.879,0,0,0,16.143,13.519Z"
        fill="#ff808b"
        fill-rule="evenodd"
      />
    </g>
  </svg>
);

const FAQItem: FunctionComponent<FAQItemProps> = ({ question, initState }) => {
  const [open, setOpen] = useState(initState);

  const onPressed = () => setOpen(!open);

  return (
    <div className="max-w-2xl py-2 border-solid border-b border-collapse border-grey-light">
      <h3
        onClick={onPressed}
        className="flex text-lg text-blue content-between justify-between"
      >
        <span>{question.question}</span>
        <span className="relative top-2">
          <Icon
            className={`transform duration-500 transition ${
              open ? '' : 'rotate-180'
            } motion-reduce:transition-none motion-reduce:transform-none`}
          />
        </span>
      </h3>
      <div className={` ${open ? 'block' : 'hidden'}`}>
        {question.answer && <MarkdownRender content={question.answer} />}
      </div>
    </div>
  );
};

const FAQs: FunctionComponent<FAQProps> = ({
  title,
  subtitle,
  introduction,
  questions,
}) => (
  <div className="px-6 items-center pb-12">
    {title && (
      <div className="flex flex-col items-center mb-8">
        <ContentItemTitle title={title} subtitle={subtitle} />
        <Underline color={UNDERLINE_COLOR.blue} />
      </div>
    )}
    {introduction && (
      <div className="flex flex-col items-center mb-8">
        <MarkdownRender
          content={introduction}
          className="font-worksans text-sm text-center mb-8 md:max-w-2xl"
        />
      </div>
    )}
    <div className="max-w-2xl m-auto border-t border-solid border-grey-light">
      {questions.map((faq, i) => (
        <FAQItem key={i} question={faq} initState={i === 0} />
      ))}
    </div>
  </div>
);

export { FAQs };
