import React, { FunctionComponent } from 'react';
import { BlueLeft } from './Brushes/BlueLeft';
import { BlueMediumRight } from './Brushes/BlueMediumRight';
import { BlueRight } from './Brushes/BlueRight';

import { GreyLeft } from './Brushes/GreyLeft';
import { GreyMediumLeft } from './Brushes/GreyMediumLeft';
import { GreyRight } from './Brushes/GreyRight';

import { NavyLeft } from './Brushes/NavyLeft';
import { NavyRight } from './Brushes/NavyRight';

import { OrangeLeft } from './Brushes/OrangeLeft';
import { OrangeMediumRight } from './Brushes/OrangeMediumRight';
import { OrangeMediumRightTwo } from './Brushes/OrangeMediumRightTwo';
import { OrangeRight } from './Brushes/OrangeRight';
import { OrangeRightTwo } from './Brushes/OrangeRightTwo';

import { PinkLeft } from './Brushes/PinkLeft';
import { PinkLeftTwo } from './Brushes/PinkLeftTwo';
import { PinkMediumLeft } from './Brushes/PinkMediumLeft';
import { PinkMediumLeftTwo } from './Brushes/PinkMediumLeftTwo';
import { PinkMediumRight } from './Brushes/PinkMediumRight';
import { PinkMediumRightTwo } from './Brushes/PinkMediumRightTwo';
import { PinkRight } from './Brushes/PinkRight';
import { PinkSmallRight } from './Brushes/PinkSmallRight';

import { YellowRight } from './Brushes/YellowRight';
import { YellowMediumLeft } from './Brushes/YellowMediumLeft';
import { YellowLeft } from './Brushes/YellowLeft';

interface BrushStrokeProps {
  brushType: string;
  children: React.ReactNode;
}

const BrushStroke: FunctionComponent<BrushStrokeProps> = ({
  brushType,
  children,
}) => (
  <div className="relative">
    {brushType === 'Blue_Left' && <BlueLeft />}
    {brushType === 'Blue_Medium_Right' && <BlueMediumRight />}
    {brushType === 'Blue_Right' && <BlueRight />}
    {brushType === 'Grey_Left' && <GreyLeft />}
    {brushType === 'Grey_Medium_Left' && <GreyMediumLeft />}
    {brushType === 'Grey_Right' && <GreyRight />}
    {brushType === 'Navy_Left' && <NavyLeft />}
    {brushType === 'Navy_Right' && <NavyRight />}
    {brushType === 'Orange_Left' && <OrangeLeft />}
    {brushType === 'Orange_Medium_Right' && <OrangeMediumRightTwo />}
    {brushType === 'Orange_Medium_Right_Two' && <OrangeMediumRightTwo />}
    {brushType === 'Orange_Right_Two' && <OrangeRightTwo />}
    {brushType === 'Orange_Right' && <OrangeRight />}
    {brushType === 'Pink_Left' && <PinkLeft />}
    {brushType === 'Pink_Left_Two' && <PinkLeftTwo />}
    {brushType === 'Pink_Medium_Left' && <PinkMediumLeft />}
    {brushType === 'Pink_Medium_Left_Two' && <PinkMediumLeftTwo />}
    {brushType === 'Pink_Medium_Right' && <PinkMediumRight />}
    {brushType === 'Pink_Medium_Right_Two' && <PinkMediumRightTwo />}
    {brushType === 'Pink_Right' && <PinkRight />}
    {brushType === 'Pink_Small_Right' && <PinkSmallRight />}
    {brushType === 'Yellow_Right' && <YellowRight />}
    {brushType === 'Yellow_Medium_Left' && <YellowMediumLeft />}
    {brushType === 'Yellow_Left' && <YellowLeft />}
    {children}
  </div>
);

export { BrushStroke };
