import React, { FunctionComponent } from 'react';
import { ButtonContent } from '@bunac/components/Programme/Button';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';
import { MarkdownRender } from '../MarkdownRender';
import { ProductGroup as ProductGroupDataItem } from '@bunac/components/Programme/ProductGroup';
import createPersistedState from 'use-persisted-state';
import {
  Currencies,
  CURRENCY_SYMBOLS,
} from '@bunac/components/config/currency';

interface ItemStyles {
  titleColor: 'blue' | 'navy' | 'white';
  subtitleColor: 'blue' | 'navy' | 'white';
  backgroundColor: 'bg-grey-light' | 'bg-yellow' | 'bg-navy' | 'bg-blue';
  buttonColor: 'pink' | 'blue' | 'navy';
  textColor?: 'text-white';
}

interface ProductGroupBaseProps {
  title?: string;
  subtitle?: string;
  introduction?: string;
  item1: ProductGroupDataItem;
  item2: ProductGroupDataItem;
  items1Styles: ItemStyles;
  items2styles: ItemStyles;
}

interface ProductGroupProps {
  title?: string;
  subtitle?: string;
  introduction?: string;
  item1: ProductGroupDataItem;
  item2: ProductGroupDataItem;
  button?: ButtonContent;
}

interface ProductGroupItemProps {
  item: ProductGroupDataItem;
  styles: ItemStyles;
}

interface ProductUnitProps {
  title: string;
  value: string;
  valueColor: 'blue' | 'navy' | 'white';
  textColor?: string;
  valueSize?: 'text-xl' | 'text-8xl';
  valueMargin?: string;
  valueFont?: 'font-fave' | undefined;
}

const useCurrency = createPersistedState('currency');

const ProductUnit: FunctionComponent<ProductUnitProps> = ({
  title,
  value,
  textColor,
  valueColor,
  valueSize = 'text-xl',
  valueMargin,
  valueFont,
}) => (
  <div className="flex flex-col items-center">
    <h4 className={`${textColor ? textColor : ''} mb-1`}>{title}</h4>
    <p
      className={`text-${valueColor} ${valueSize} ${valueMargin} ${
        valueFont ? valueFont : ''
      } font-semibold`}
    >
      {value}
    </p>
  </div>
);

const calculateTotal = (symbol: string, products: number[]) =>
  `${symbol}${products.reduce(
    (sum: number, current: number) => sum + current,
    0
  )}`;

const valueDisplay = (symbol: string, value?: number) => {
  if (value === undefined) {
    return `${symbol}0`;
  }
  return `${symbol}${value}`;
};

const ProductGroupItem: FunctionComponent<ProductGroupItemProps> = ({
  item,
  styles,
}) => {
  const [activeCurrency] = useCurrency(Currencies.default);
  const symbol =
    CURRENCY_SYMBOLS[activeCurrency as keyof typeof CURRENCY_SYMBOLS];

  const costs = [
    item.deposit?.cost
      ? item.deposit.cost[activeCurrency as keyof typeof CURRENCY_SYMBOLS]
      : 0,
    item.firstPayment?.cost
      ? item.firstPayment.cost[activeCurrency as keyof typeof CURRENCY_SYMBOLS]
      : 0,
    item.secondPayment?.cost
      ? item.secondPayment.cost[activeCurrency as keyof typeof CURRENCY_SYMBOLS]
      : 0,
  ];

  return (
    <div className={`flex flex-1 flex-col lg:max-w-xl mb-8`}>
      <div
        className={`relative pt-6 rounded-3xl px-4 pb-6  z-1 ${styles.backgroundColor} md:px-8`}
      >
        {item.title && (
          <div className="flex flex-col items-center">
            <ContentItemTitle
              title={item.title}
              subtitle={item.subtitle}
              bottomMargin={0}
              titleColor={styles.titleColor}
              subtitleColor={styles.subtitleColor}
              inverted={true}
            />
            <Underline
              color={UNDERLINE_COLOR[styles.subtitleColor]}
              className={'mb-4'}
            />
          </div>
        )}
        {item.introduction && (
          <MarkdownRender
            content={item.introduction}
            className={`text-center text-sm font-semibold mt-4 mb-8 max-w-2xl editor-cost-intro ${
              styles.textColor ? styles.textColor : ''
            }`}
          />
        )}
        {item.deposit && item.deposit.title && item.deposit.cost && (
          <ProductUnit
            title={item.deposit.title}
            value={valueDisplay(
              symbol,
              item.deposit.cost[activeCurrency as keyof typeof CURRENCY_SYMBOLS]
            )}
            textColor={styles.textColor}
            valueColor={styles.subtitleColor}
          />
        )}
        {item.firstPayment &&
          item.firstPayment.title &&
          item.firstPayment.cost && (
            <ProductUnit
              title={item.firstPayment.title}
              value={valueDisplay(
                symbol,
                item.firstPayment.cost[
                  activeCurrency as keyof typeof CURRENCY_SYMBOLS
                ]
              )}
              textColor={styles.textColor}
              valueColor={styles.subtitleColor}
            />
          )}
        {item.secondPayment &&
          item.secondPayment.title &&
          item.secondPayment.cost && (
            <ProductUnit
              title={item.secondPayment.title}
              value={valueDisplay(
                symbol,
                item.secondPayment.cost[
                  activeCurrency as keyof typeof CURRENCY_SYMBOLS
                ]
              )}
              textColor={styles.textColor}
              valueColor={styles.subtitleColor}
            />
          )}
        {item.includeTotal && (
          <ProductUnit
            title={'Total:'}
            value={calculateTotal(symbol, costs)}
            textColor={styles.textColor}
            valueColor={styles.subtitleColor}
            valueSize={`text-8xl`}
            valueFont="font-fave"
            valueMargin="my-4"
          />
        )}
        {item.details && (
          <MarkdownRender
            content={item.details}
            className={`text-center mt-4 mb-8 px-2 editor-cost-details-${styles.subtitleColor}`}
          />
        )}
        {item.button && item.button.url && (
          <div className="flex justify-center mt-3 mb-4">
            <LinkButton
              cta={item.button.text}
              url={item.button.url}
              color={styles.buttonColor}
            />
          </div>
        )}
        {item.finalText && (
          <MarkdownRender
            content={item.finalText}
            className="editor-text-xs text-center text-xs mt-12 mb-4 max-w-2xl"
          />
        )}
      </div>
    </div>
  );
};

const ProductGroupBlockBase: FunctionComponent<ProductGroupBaseProps> = ({
  title,
  subtitle,
  introduction,
  item1,
  item2,
  items1Styles,
  items2styles,
}) => (
  <div className="py-12 px-4 lg:pt-0 lg:pb-32 md:pb-40 md:flex md:flex-col md:items-center md:justify-center">
    {title && (
      <ContentItemTitle
        title={title}
        subtitle={subtitle}
        bottomMargin={2}
        titleColor="blue"
        subtitleColor="navy"
      />
    )}
    {title && (
      <div className="flex justify-center">
        <Underline />
      </div>
    )}
    {introduction && (
      <MarkdownRender
        content={introduction}
        className="text-center text-sm mt-4 mb-8 max-w-2xl"
      />
    )}
    <div className="md:flex md:flex-row">
      <ProductGroupItem item={item1} styles={items1Styles} />
      <div className="flex-1 md:relative md:top-20 md:ml-10">
        <ProductGroupItem item={item2} styles={items2styles} />
      </div>
    </div>
  </div>
);

const ProductGroup: FunctionComponent<ProductGroupProps> = ({
  title,
  subtitle,
  introduction,
  item1,
  item2,
}) => (
  <ProductGroupBlockBase
    title={title}
    subtitle={subtitle}
    introduction={introduction}
    item1={item1}
    items1Styles={{
      titleColor: 'navy',
      subtitleColor: 'blue',
      backgroundColor: 'bg-grey-light',
      buttonColor: 'pink',
    }}
    item2={item2}
    items2styles={{
      titleColor: 'white',
      subtitleColor: 'navy',
      backgroundColor: 'bg-blue',
      buttonColor: 'pink',
      textColor: 'text-white',
    }}
  />
);

export { ProductGroup };
