import React, { FunctionComponent } from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { ButtonDataSource } from '@bunac/components/Page/datasource';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';

interface ColourAdBlockBasicProps {
  title: string;
  subtitle: string;
  content?: string;
  button?: ButtonDataSource;
}

const BasicColourAdBlock: FunctionComponent<ColourAdBlockBasicProps> = ({
  title,
  subtitle,
  content,
  button,
}) => (
  <div className="px-6 py-12">
    <div className={`relative flex flex-col items-center`}>
      <ContentItemTitle
        title={title}
        subtitle={subtitle}
        bottomMargin={2}
        titleColor={'blue'}
        subtitleColor={'navy'}
        inverted={false}
      />
      {content && (
        <MarkdownRender
          className={'color-ad-block-full text-center lg:px-20 md:max-w-4xl'}
          content={content}
        />
      )}
      {button && button?.Text && button?.URL && (
        <div className="flex mt-6">
          <LinkButton cta={button.Text} url={button.URL} color="blue" />
        </div>
      )}
    </div>
  </div>
);

export { BasicColourAdBlock };
