import React, { FunctionComponent } from 'react';
import { ContentItem } from '@bunac/components/Programme/ContentItem';
import { QuoteDataSource } from '@bunac/components/Page/datasource';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';
import { MarkdownRender } from '../MarkdownRender';
import { Quotes } from './Quotes';
import { Image } from '@bunac/components/Programme/Image';

interface TextAndQuoteProps {
  title?: string;
  subtitle?: string;
  introduction?: string;
  item: ContentItem;
  quotes: QuoteDataSource[];
  variant: 'Dark' | 'Light';
}

interface ContentItemViewProps {
  item: ContentItem;
  titleColor: 'blue' | 'navy';
  variant: 'Dark' | 'Light';
}

const ContentItemView: FunctionComponent<ContentItemViewProps> = ({
  item,
  variant,
}) => (
  <div
    className={`flex flex-1 flex-col lg:max-w-md ${item.image ? '' : 'mb-8'}`}
  >
    {item.image && (
      <div className="relative ml-3 mr-3 z-50">
        <Image
          className="rounded-3xl m-auto"
          image={item.image}
          alt={item.title}
        />
      </div>
    )}
    <div
      className={`relative flex flex-col items-center ${
        item.image ? '-top-24 -mb-16 pt-28' : 'pt-6'
      } rounded-3xl px-4 pb-6  z-1 ${
        variant === 'Dark' ? 'bg-navy' : 'bg-grey-light'
      } md:px-8`}
    >
      {item.title && (
        <ContentItemTitle
          title={item.title}
          subtitle={item.subtitle}
          bottomMargin={variant === 'Dark' ? 0 : 2}
          titleColor={variant === 'Dark' ? 'blue' : 'navy'}
          subtitleColor={variant === 'Dark' ? 'white' : 'blue'}
          inverted={variant === 'Light'}
        />
      )}
      {variant === 'Dark' && item.title && (
        <Underline color={UNDERLINE_COLOR.blue} className="mb-6" />
      )}
      <div
        className={`text-center font-worksans text-sm mb-4 ${
          variant === 'Dark' ? 'text-white' : ''
        }`}
      >
        <MarkdownRender content={item.text} />
      </div>
      {item.button && item.button.url && (
        <div className="flex justify-center mt-3 mb-4">
          <LinkButton
            cta={item.button.text}
            url={item.button.url}
            iconColor={variant === 'Dark' ? 'blue' : 'navy'}
            color={variant === 'Dark' ? 'yellow' : 'pink'}
          />
        </div>
      )}
    </div>
  </div>
);

const TextAndQuote: FunctionComponent<TextAndQuoteProps> = ({
  title,
  subtitle,
  introduction,
  item,
  quotes,
  variant,
}) => (
  <div className="py-12 px-4 md:flex md:flex-col md:items-center md:justify-center">
    {title && (
      <ContentItemTitle
        title={title}
        subtitle={subtitle}
        bottomMargin={2}
        titleColor="blue"
        subtitleColor="navy"
      />
    )}
    {title && (
      <div className="flex justify-center">
        <Underline />
      </div>
    )}
    {introduction && (
      <div className="text-center text-sm mt-4 mb-8 max-w-2xl">
        <MarkdownRender content={introduction} />
      </div>
    )}
    <div className="md:flex md:flex-row md:items-center md:align-middle md:justify-center md:w-full md:space-x-4">
      <ContentItemView item={item} titleColor={'blue'} variant={variant} />
      <div className="flex-1 md:relative md:top-20 lg:max-w-lg">
        <Quotes
          quotes={quotes.map((quote: QuoteDataSource) => ({
            content: quote.Content,
            experience: quote.Experience,
            person: quote.Person,
          }))}
        />
      </div>
    </div>
  </div>
);

export { TextAndQuote };
