import React, { FunctionComponent } from 'react';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Image } from '@bunac/components/Programme/Image';

interface CTAProps {
  title: string;
  subtitle: string;
  url: string;
  cta: string;
  image: ImageDataLike;
}

const CTA: FunctionComponent<CTAProps> = ({
  title,
  subtitle,
  url,
  cta,
  image,
}) => (
  <div className="md:my-12 md:px-4 md:flex md:justify-center md:max-w-4xl md:m-auto">
    <div className="relative mx-4 z-50 md:mx-0 md:flex">
      <Image
        className="m-auto rounded-3xl md:rounded-r-none"
        image={image}
        alt={title}
      />
    </div>
    <div
      className={`flex flex-col flex-grow items-center relative -z-10 -top-28 pt-32 bg-navy p-8 md:rounded-r-3xl md:top-0 md:pt-8`}
    >
      <ContentItemTitle
        title={title}
        subtitle={subtitle}
        bottomMargin={2}
        titleColor="yellow"
        subtitleColor="white"
      />
      <div className="flex mt-6">
        <LinkButton cta={cta} url={url} color="blue" />
      </div>
    </div>
  </div>
);

export { CTA };
