import React, { FunctionComponent, useState, useCallback } from 'react';
import { Underline, UNDERLINE_COLOR } from '../Decoration/Underline';
import { Quote } from '../Quote';
import { ContentItemTitle } from './ContentItemTitle';
import { Quotes } from './Quotes';

interface TabQuote {
  title: string;
  quotes: Quote[];
}

interface TextProps {
  title: string;
  subtitle: string;
  tabs: TabQuote[];
}

interface TabTitleProps {
  title: string;
  current: boolean;
  onClick: (tab: string) => void;
}

const CURRENT_STYLES = `font-normal text-navy border-b-4 border-solid border-navy`;
const NORMAL_STYLES = `text-grey border-b-4 border-solid border-white`;

const TabTitle: FunctionComponent<TabTitleProps> = ({
  title,
  current,
  onClick,
}) => (
  <button
    className={`font-worksans pb-2 text-2xl ${
      current ? CURRENT_STYLES : NORMAL_STYLES
    }`}
    onClick={() => onClick(title)}
  >
    {title}
  </button>
);

const TabbedQuotes: FunctionComponent<TextProps> = ({
  title,
  subtitle,
  tabs,
}) => {
  const [tab, setTab] = useState<string>(tabs[0]?.title);

  const onTabPressed = useCallback(
    (nextTab: string) => setTab(nextTab),
    [setTab]
  );

  if (tabs.length === 0) {
    return <></>;
  }

  return (
    <div className="py-12 px-6">
      {title && (
        <>
          <ContentItemTitle
            title={title}
            subtitle={subtitle}
            titleColor={subtitle ? 'navy' : 'blue'}
            subtitleColor={'blue'}
            bottomMargin={0}
            inverted={true}
            bold={true}
          />
          <Underline color={UNDERLINE_COLOR.blue} className={'mb-8'} />
        </>
      )}
      <div className="flex flex-row mb-8 justify-center space-x-8">
        {tabs.map((t: TabQuote, i) => (
          <TabTitle
            key={i}
            current={t.title === tab}
            title={t.title}
            onClick={onTabPressed}
          />
        ))}
      </div>
      <div className="block min-h-full">
        {tabs.map((t: TabQuote, i) => (
          <div key={i} className={`${t.title === tab ? '' : 'opacity-0 h-0'} `}>
            <Quotes quotes={t.quotes} />
          </div>
        ))}
      </div>
    </div>
  );
};

export { TabbedQuotes };
