import React, { FunctionComponent, useState } from 'react';
import { ContentItem } from '@bunac/components/Programme/ContentItem';
import { ContentItemTitle } from './ContentItemTitle';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import {
  LinkButton,
  LinkButtonStyle,
} from '@bunac/components/Programme/LinkButton';
import { Image } from '@bunac/components/Programme/Image';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';

interface StackedProps {
  title: string;
  subtitle?: string;
  introduction?: string;
  items: ContentItem[];
}

interface ContentItemViewProps {
  index: number;
  item: ContentItem;
  selected: boolean;
}

const BACKGROUND_COLORS = [
  'bg-yellow',
  'bg-blue',
  'bg-navy',
  'bg-blue',
  'bg-yellow',
];

const BUTTON_COLORS = ['blue', 'pink', 'blue', 'pink', 'blue'];

type Colors = 'blue' | 'navy' | 'white';

type AltColors = 'blue' | 'navy' | 'white' | 'yellow';

const TITLE_COLORS: AltColors[] = ['navy', 'white', 'white', 'white', 'navy'];

const SUBTITLE_COLORS: Colors[] = ['navy', 'navy', 'blue', 'navy', 'navy'];

const TEXT_COLORS = ['', 'text-white', 'text-white', 'text-white'];

const ContentItemView: FunctionComponent<ContentItemViewProps> = ({
  index,
  item,
  selected,
}) => {
  const titleColor: AltColors = TITLE_COLORS[index] || 'navy';
  const subtitleColor: Colors = SUBTITLE_COLORS[index] || 'navy';
  const backgroundColor = BACKGROUND_COLORS[index];
  const textColor = TEXT_COLORS[index];
  const buttonColor = BUTTON_COLORS[index];

  const order = index % 2 === 0 ? 'lg:flex-row-reverse' : 'lg:flex-row';
  const position = index % 2 === 0 ? 'lg:-right-36' : 'lg:-left-36';
  const padding = index % 2 === 0 ? 'lg:pr-80' : 'lg:pl-80';
  const margin = index % 2 === 0 ? 'lg:mr-40' : 'lg:ml-40';

  return (
    <div
      className={`flex flex-1 flex-col px-4 ${item.image ? '' : 'mb-8'} ${
        selected ? '' : 'hidden lg:block'
      }
        lg:pb-36 lg:flex ${order} lg:items-start lg:relative lg:mx-6
        lg:max-w-4xl ${margin} px-0
      `}
    >
      {item.image && (
        <div
          className={`relative ml-6 mr-6 z-50
          lg:max-w-md lg:absolute lg:top-8 ${position} lg:flex-1`}
        >
          <Image
            className="m-auto rounded-3xl filter drop-shadow-images stacked-image object-cover"
            image={item.image}
            alt={item.title}
          />
        </div>
      )}
      <div
        className={`relative ${
          item.image ? '-top-24 -mb-16 pt-28' : 'pt-6'
        } rounded-3xl px-4 pb-6 ${padding} z-1 ${backgroundColor} md:px-8
          lg:top-0 lg:py-10 lg:pb-16
        `}
      >
        {item.title && (
          <ContentItemTitle
            title={item.title}
            subtitle={item.subtitle}
            bottomMargin={2}
            titleColor={item.subtitle ? titleColor : subtitleColor}
            subtitleColor={subtitleColor}
            inverted={false}
            bold={true}
          />
        )}
        <div
          className={`text-center font-worksans text-sm mb-8 ${textColor} lg:mb-0 lg:px-8 lg:pr-16`}
        >
          <MarkdownRender content={item.text} />
        </div>
        {item.button && item.button.url && (
          <div className="flex justify-center mt-6 mb-4">
            <LinkButton
              cta={item.button.text}
              url={item.button.url}
              color={buttonColor as LinkButtonStyle}
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface ContentIndexProps {
  index: number;
  item: ContentItem;
  selected: boolean;
  onClick: (index: number) => void;
}

const ContentIndex: FunctionComponent<ContentIndexProps> = ({
  index,
  item,
  selected,
  onClick,
}) => (
  <li>
    <button
      onClick={() => onClick(index)}
      className={`text-lg transition duration-400 ease-in-out ${
        selected ? 'text-blue font-semibold' : 'text-navy'
      }`}
    >
      {selected ? '> ' : ''}
      {item.title}
    </button>
  </li>
);

const Stacked: FunctionComponent<StackedProps> = ({
  title,
  subtitle,
  introduction,
  items,
}) => {
  const [selected, setSelected] = useState(0);

  const onItemClicked = (index: number) => {
    setSelected(index);
  };

  return (
    <div className="py-12 flex flex-col items-center">
      <ContentItemTitle
        title={title}
        subtitle={subtitle}
        bottomMargin={2}
        titleColor={'navy'}
        subtitleColor={'blue'}
        inverted={true}
      />
      {title && (
        <Underline color={UNDERLINE_COLOR.blue} className={'mb-8 lg:mb-12'} />
      )}
      {introduction && (
        <MarkdownRender
          content={introduction}
          className="font-worksans text-sm text-center mb-8 px-6 md:max-w-2xl"
        />
      )}
      <ul className="lg:hidden text-center mb-8">
        {items.map((item, i) => (
          <ContentIndex
            item={item}
            key={i}
            index={i}
            selected={selected === i}
            onClick={onItemClicked}
          />
        ))}
      </ul>
      <div className="">
        {items.map((item, i) => (
          <ContentItemView
            item={item}
            index={i}
            key={i}
            selected={selected === i}
          />
        ))}
      </div>
    </div>
  );
};

export { Stacked };
