import React, { FunctionComponent } from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { Underline } from '@bunac/components/Programme/Decoration/Underline';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import { ButtonDataSource } from '@bunac/components/Page/datasource';
import { LinkButton } from '../LinkButton';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

interface TextAndVideoProps {
  title: string;
  subtitle?: string;
  introduction?: string;
  url?: string;
  button: ButtonDataSource;
}

const TextAndVideo: FunctionComponent<TextAndVideoProps> = ({
  title,
  subtitle,
  introduction,
  url,
  button,
}) => {
  const videoCodePattern = new RegExp(/[^/]+$/);
  const match = url?.match(videoCodePattern);
  const videoCode = match ? match[0] : null;

  return (
    <div className="pt-12 lg:pt-2">
      <div className="py-8 px-4 mx-auto flex flex-col items-center justify-center">
        <ContentItemTitle title={title} subtitle={subtitle} bottomMargin={0} />
        {title && <Underline className="mb-8" />}
        {introduction && (
          <div className="text-center md:max-w-4xl">
            <MarkdownRender content={introduction} />
          </div>
        )}
        {button && button.Text && button.URL && (
          <div className="mt-4 mb-8">
            <LinkButton cta={button.Text} url={button.URL} color={'pink'} />
          </div>
        )}
      </div>
      {videoCode && (
        <>
          <style jsx global>{`
            html,
            body {
              padding: 0;
              font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                sans-serif;
            }

            .yt-lite {
              background-color: #000;
              position: relative;
              display: block;
              contain: content;
              background-position: center center;
              background-size: cover;
              cursor: pointer;
            }

            /* responsive iframe with a 16:9 aspect ratio
                      thanks https://css-tricks.com/responsive-iframes/
                  */
            .yt-lite::after {
              content: '';
              display: block;
              padding-bottom: calc(100% / (16 / 9));
            }
            .yt-lite > iframe {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }

            /* play button */
            .yt-lite > .lty-playbtn {
              width: 70px;
              height: 46px;
              background-color: #212121;
              z-index: 1;
              opacity: 0.8;
              border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
              transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
            }
            .yt-lite:hover > .lty-playbtn {
              background-color: #f00;
              opacity: 1;
            }
            /* play button triangle */
            .yt-lite > .lty-playbtn:before {
              content: '';
              border-style: solid;
              border-width: 11px 0 11px 19px;
              border-color: transparent transparent transparent #fff;
            }

            .yt-lite > .lty-playbtn,
            .yt-lite > .lty-playbtn:before {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0);
            }

            /* Post-click styles */
            .yt-lite.lyt-activated {
              cursor: unset;
            }
            .yt-lite.lyt-activated::before,
            .yt-lite.lyt-activated > .lty-playbtn {
              opacity: 0;
              pointer-events: none;
            }
          `}</style>
          <LiteYouTubeEmbed
            id={videoCode}
            title={title}
            noCookie={true}
            poster="sddefault"
          />
        </>
      )}
    </div>
  );
};

export { TextAndVideo };
