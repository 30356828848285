import React, { FunctionComponent } from 'react';
import ReviewsCarousel from '@bunac/components/reviewsBanners/ReviewsCarousel';
interface ReviewsProps {
  title?: string;
  subtitle?: any;
}

const Reviews: FunctionComponent<ReviewsProps> = ({ title, subtitle }) => (
  <div className="relative grid grid-cols-12 gap-y-8 w-full xl:w-11/12 mx-auto py-12 md:px-6">
    <img className='absolute -right-24 -top-60 invisible xl:visible'
      src="/svg/testimonial-right.svg"
      alt="Blue brush stroke positioned on the left top side"
    />
    <div className="col-span-12 space-y-3">
      <img className='mx-auto' src="/svg/quotes.svg" alt="Testimonials quotes icon"/>
      <h4 className='font-worksans text-2xl text-center text-teal'>{title || ''}</h4>
      <h1 className='font-fave font-bold text-6xl text-center text-navy'>{subtitle || ''}</h1>
    </div>
    <div className="col-span-12 mt-8">
      <ReviewsCarousel component='reviews_component'/>
    </div>
  </div>
);

export { Reviews };
