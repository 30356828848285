import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from './text';

const EmptyBox = styled.div`
  @media screen and (min-width: 960px) {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex: 35%;
    flex: 35%;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
  }
`;

const StepNumber = styled.h3`
  margin-bottom: 0;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  order: 1;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  margin: 0 16px 16px;
  background-color: #0094a5;
  border-radius: 50px;
  color: #fff;

  &.theme-usa {
    background-color: #f63933;
  }

  @media screen and (max-width: 960px) {
    margin: auto;
    margin-bottom: 16px;
  }
`;

const StepText = styled.div`
  background-color: #f9f9f9;
  color: #222;
  padding: 16px;
  position: relative;
  margin-bottom: 16px;

  &.theme-usa {
    background-color: #08599b;
    color: #fff;

    :before {
      color: #08599b;
    }
  }

  @media screen and (min-width: 960px) {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    order: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex: 35%;
    flex: 35%;
  }

  :before {
    content: '\f0da';
    font-family: FontAwesome;
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    color: #f9f9f9;
    font-size: 20px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -7px;
  }
`;

const Container = styled.ul`
  list-style-type: none;
  width: 100%;
  max-width: 1000px;
  margin: 32px 0;
`;

const Step = styled.li`
  @media screen and (min-width: 960px) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;

    &:nth-child(even) {
      ${StepText} {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        order: 2;
        content: '\f0d9';

        :before {
          content: '\f0d9';
          left: -7px;
        }
      }

      ${EmptyBox} {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        order: 0;
      }
    }
  }

  padding: 0;

  :before {
    display: none;
  }
`;

interface StepModel {
  Title: string;
  Content: string;
}

interface StepListProps {
  steps: StepModel[];
  theme?: string;
}

const StepList: FunctionComponent<StepListProps> = ({ steps, theme }) => (
  <Container>
    {steps.map((step, index) => (
      <Step key={index}>
        <EmptyBox />
        <StepNumber className={`theme-${theme}`}>{index + 1}</StepNumber>
        <StepText className={`theme-${theme}`}>
          {step.Title && <h2>{step.Title}</h2>}
          <Text>{step.Content}</Text>
        </StepText>
      </Step>
    ))}
    <br />
  </Container>
);

export { StepList };
