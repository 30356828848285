import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Item from './Item';

interface GalleryProps {
  children?: ReactElement[];
}

const Gallery: FunctionComponent<GalleryProps> = ({ children }) => {
  return (
    <StyledCarousel
      additionalTransfrom={0}
      arrows={true}
      draggable={true}
      keyBoardControl={true}
      responsive={{
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 3,
          partialVisibilityGutter: 40,
        },
      }}
      showDots={true}
      slidesToSlide={1}
      partialVisible={true}
    >
      {children}
    </StyledCarousel>
  );
};

const StyledCarousel = styled(Carousel)`
  background: #f9f9f9;
  padding-top: 48px;
  padding-bottom: 68px !important;

  .react-multi-carousel-item,
  .react-multi-carousel-dot {
    padding-left: 0;
    font-weight: normal;
    &:before {
      content: '';
      width: 0px;
      position: relative;
      height: 0px;
      top: 0px;
      left: 0px;
      background: transparent;
    }
  }
  .react-multi-carousel-dot-list {
    bottom: 28px;
  }
  .react-multi-carousel-dot {
    button {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #0094a5;
      border: 1px solid transparent;
      margin-right: 16px;
      transition: all 0.2s ease;
    }
  }
  .react-multi-carousel-dot--active {
    button {
      border: 1px solid #0094a5;
      background-color: rgba(0, 0, 0, 0);
      transform: scale(1.5, 1.5);
    }
  }

  .react-multiple-carousel__arrow {
    height: 60px;
    width: 60px;
    top: 33%;
    color: #fff;
    border-radius: 0;
    background: #ffad01;
    &:before {
      font-weight: 400;
      text-decoration: inherit;
      font-size: 18px;
      position: absolute;
      top: 20px;
      right: 20px;
      font-family: FontAwesome;
      font-display: swap;
      font-style: normal;
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 0px;
    &:before {
      content: '\f177';
    }
  }
  .react-multiple-carousel__arrow--right {
    right: 0px;
    &:before {
      content: '\f178';
    }
  }
`;

export { Item as GalleryItem };
export default Gallery;
