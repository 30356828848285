import React, { FunctionComponent } from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { ButtonDataSource } from '@bunac/components/Page/datasource';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Image } from '@bunac/components/Programme/Image';

interface ColourAdBlockFullProps {
  title: string;
  subtitle: string;
  content?: string;
  button?: ButtonDataSource;
  image?: ImageDataLike;
  color: 'yellow' | 'blue' | 'navy' | 'pink' | 'transparent' | null;
  imageLayout: 'full' | 'thumbnail' | 'small' | 'none';
}

const FullColourAdBlock: FunctionComponent<ColourAdBlockFullProps> = ({
  title,
  subtitle,
  content,
  button,
  image,
  color,
}) => (
  <div className="lg:pb-24">
    <Image
      className="object-contain h-96 lg:h-auto"
      image={image}
      alt={title}
    />
    <div
      className={`filter drop-shadow-images relative flex flex-col items-center rounded-3xl bg-${color} ${
        color === 'yellow' ? '' : 'text-white'
      } p-4 pb-6 mx-4 -top-28 -mb-24
      md:pb-12 md:-mb-12
      lg:pb-12 lg:pt-8 lg:m-auto lg:max-w-screen-md lg:-top-52 lg:px-12 lg:-mb-52`}
    >
      <ContentItemTitle
        title={title}
        subtitle={subtitle}
        bottomMargin={0}
        titleColor={color === 'yellow' ? 'navy' : 'white'}
        subtitleColor={'navy'}
        inverted={true}
      />
      {title && (
        <Underline
          color={
            color === 'yellow' ? UNDERLINE_COLOR.blue : UNDERLINE_COLOR.white
          }
          className={'mb-2'}
        />
      )}
      {content && (
        <MarkdownRender
          className={'color-ad-block-full text-center lg:px-12'}
          content={content}
        />
      )}
      {button && button?.Text && button?.URL && (
        <div className="flex mt-6">
          <LinkButton
            cta={button.Text}
            url={button.URL}
            color={color === 'blue' ? 'navy' : 'blue'}
          />
        </div>
      )}
    </div>
  </div>
);

export { FullColourAdBlock };
