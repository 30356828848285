import React, { FunctionComponent } from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';
import { MarkdownRender } from '../MarkdownRender';
import {
  ButtonDataSource,
  JobRoleDataSource,
} from '@bunac/components/Page/datasource';
import { Image } from '@bunac/components/Programme/Image';
import { LinkButton } from '../LinkButton';
import formatSlug from '@bunac/lib/slugFormatter';

interface JobRoleProps {
  role: JobRoleDataSource;
}

const JobRole: FunctionComponent<JobRoleProps> = ({ role }) => (
  <div
    className="flex flex-col items-center mb-20"
    id={formatSlug([role.Title]).replace('/', '')}
  >
    {role.Title && (
      <div className="flex flex-col items-center px-8">
        <ContentItemTitle
          title={role.Title}
          subtitle={`jobs in ${role.Location}`}
          inverted={false}
          titleColor={'blue'}
          subtitleColor={'navy'}
          bottomMargin={0}
        />
        <Underline color={UNDERLINE_COLOR.blue} className={'mb-8'} />
      </div>
    )}
    <div className="text-center px-8 mb-6 md:px-16 lg:max-w-4xl">
      <MarkdownRender content={role.Overview} />
    </div>
    <div className="flex flex-col xl:grid xl:grid-cols-2 xl:mb-52">
      <div className="px-8 mb-6 md:px-16 lg:py-20">
        <MarkdownRender content={role.Details} />
      </div>
      <div className="blue-l-box">
        <div className="blue-t-box">
          <div className="blue-b-box">
            <div className="px-8 py-8 bg-blue text-white md:px-16 xl:py-20 xl:pb-64">
              <MarkdownRender content={role.Outcomes} className="editor-role" />
            </div>
          </div>
        </div>
      </div>
      <div className="xl:relative">
        <div className="w-full xl:absolute xl:-right-20 xl:-top-48">
          <Image
            image={role.Key_Visual}
            alt={role.Title}
            className="object-fill w-full xl:rounded-3xl"
          />
        </div>
      </div>
      {role.Actions && (
        <div className="xl:max-w-md relative">
          <div className="relative rounded-3xl bg-pink px-4 pt-6 pb-8 mx-3 -top-20 -mb-20 md:mx-6 xl:absolute xl:filter xl:drop-shadow-images">
            <h4 className="text-white text-4xl text-center font-worksans font-light mb-8">
              Find out more
            </h4>
            <div className="flex flex-col space-y-4 mx-12 md:mx-48 lg:mx-64 xl:mx-8">
              {role.Actions.map((button: ButtonDataSource, i) => (
                <LinkButton
                  key={i}
                  cta={button.Text}
                  url={button.URL}
                  color={'white'}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

const JobRoleAlternative: FunctionComponent<JobRoleProps> = ({ role }) => (
  <div
    className="flex flex-col items-center mb-20"
    id={formatSlug([role.Title]).replace('/', '')}
  >
    {role.Title && (
      <div className="flex flex-col items-center px-8">
        <ContentItemTitle
          title={role.Title}
          subtitle={`jobs in ${role.Location}`}
          inverted={false}
          titleColor={'blue'}
          subtitleColor={'navy'}
          bottomMargin={0}
        />
        <Underline color={UNDERLINE_COLOR.blue} className={'mb-8'} />
      </div>
    )}
    <div className="text-center px-8 mb-6 md:px-16 lg:max-w-4xl">
      <MarkdownRender content={role.Overview} />
    </div>
    <div className="flex flex-col xl:grid xl:grid-cols-2 xl:mb-52">
      <div className="navy-r-box">
        <div className="navy-t-box">
          <div className="navy-b-box">
            <div className="px-8 py-8 bg-navy text-white md:px-16 xl:py-20 xl:pb-64">
              <MarkdownRender content={role.Outcomes} className="editor-role" />
            </div>
          </div>
        </div>
      </div>
      <div className="px-8 mt-6 mb-6 md:px-16 lg:py-20">
        <MarkdownRender content={role.Details} />
      </div>
      {role.Actions && (
        <div className="hidden relative xl:block xl:max-w-md xl:w-full">
          <div className="relative rounded-3xl bg-yellow px-4 pt-6 pb-8 mx-3 -top-20 -mb-20 md:mx-6 xl:absolute xl:-right-40 z-10 xl:filter xl:drop-shadow-images">
            <h4 className="text-navy text-3xl text-center font-worksans font-light mb-8">
              Find out more
            </h4>
            <div className="flex flex-col space-y-4 mx-12 md:mx-48 lg:mx-64 xl:mx-8">
              {role.Actions.map((button: ButtonDataSource, i) => (
                <LinkButton
                  key={i}
                  cta={button.Text}
                  url={button.URL}
                  color={'navy'}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="xl:relative">
        <div className="w-full xl:absolute xl:-left-20 xl:-top-48">
          <Image
            image={role.Key_Visual}
            alt={role.Title}
            className="object-fill w-full xl:rounded-3xl"
          />
        </div>
      </div>
    </div>
    {role.Actions && (
      <div className="relative w-full xl:hidden">
        <div className="relative rounded-3xl bg-yellow px-4 pt-6 pb-8 mx-3 -top-20 -mb-20 md:mx-6 z-10">
          <h4 className="text-navy text-3xl text-center font-worksans font-light mb-8">
            Find out more
          </h4>
          <div className="flex flex-col space-y-4 mx-12 md:mx-48 lg:mx-64">
            {role.Actions.map((button: ButtonDataSource, i) => (
              <LinkButton
                key={i}
                cta={button.Text}
                url={button.URL}
                color={'navy'}
              />
            ))}
          </div>
        </div>
      </div>
    )}
  </div>
);

export { JobRole, JobRoleAlternative };
