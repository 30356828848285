import React, { FunctionComponent } from 'react';
import { Underline, UNDERLINE_COLOR } from '../Decoration/Underline';
import { MarkdownRender } from '../MarkdownRender';
import { ContentItemTitle } from './ContentItemTitle';
import { Currencies } from '@bunac/components/config/currency';
import createPersistedState from 'use-persisted-state';

interface RestrictedContentProps {
  title: string;
  subtitle: string;
  content: string;
  location: string;
}

const useCurrency = createPersistedState('currency');

const Content: FunctionComponent<RestrictedContentProps> = ({
  title,
  subtitle,
  content,
}) => (
  <div className="py-12 px-6 flex flex-col items-center">
    {title && (
      <>
        <ContentItemTitle
          title={title}
          subtitle={subtitle}
          titleColor={'navy'}
          subtitleColor={'blue'}
          bottomMargin={0}
          inverted={true}
        />
        <Underline color={UNDERLINE_COLOR.blue} className={'mb-8'} />
      </>
    )}
    <div className="text-center lg:max-w-2xl mb-8">
      <MarkdownRender content={content} />
    </div>
  </div>
);

const RestrictedContent: FunctionComponent<RestrictedContentProps> = (
  props
) => {
  const [current] = useCurrency(Currencies.default);

  if (current !== props.location) {
    return <></>;
  }
  return <Content {...props} />;
};

export { RestrictedContent };
