import React, { FunctionComponent } from 'react';

interface PaperProps {
  children: React.ReactNode;
}

const Paper: FunctionComponent<PaperProps> = ({ children }) => (
  <div className="paper-top">
    <div className="paper-bottom">
      <div className="paper">{children}</div>
    </div>
  </div>
);

const PaperTop: FunctionComponent<PaperProps> = ({ children }) => (
  <div className="paper-top top-only">
    <div className="paper top-only">{children}</div>
  </div>
);

const PaperBottom: FunctionComponent<PaperProps> = ({ children }) => (
  <div className="paper-bottom bottom-only">
    <div className="paper bottom-only">{children}</div>
  </div>
);

const PaperHalf: FunctionComponent<PaperProps> = ({ children }) => (
  <div className="background-half">
    <div className="paper-half" />
    {children}
  </div>
);

export { Paper, PaperTop, PaperBottom, PaperHalf };
