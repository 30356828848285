import React, { FunctionComponent } from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';
import { ButtonDataSource } from '@bunac/components/Page/datasource';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { MarkdownRender } from '../MarkdownRender';
import { ContentCarousel } from '@bunac/components/Programme/ContentCarousel';

interface Step {
  title: string;
  subtitle: string;
  content: string;
}

interface StepsProps {
  title: string;
  subtitle: string;
  steps: Step[];
  button?: ButtonDataSource;
}

interface StepProp {
  index: number;
  step: Step;
}

const STEPS = [
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
  'Ten',
];

const StepItem: FunctionComponent<StepProp> = ({ step, index }) => (
  <div className={`pb-6 ${!(index % 2 === 0) ? 'lg:pt-20' : ''}`}>
    <div className="text-center lg:hidden">
      <h4 className="uppercase font-semibold text-navy">Step {STEPS[index]}</h4>
    </div>
    {step.title && (
      <div className="flex flex-col items-center">
        <ContentItemTitle
          title={step.title}
          subtitle={step.subtitle}
          titleSize={'text-5xl'}
          subtitleSize={'text-xl'}
          inverted={true}
          subtitleColor={'blue'}
          titleColor={'navy'}
          bottomMargin={0}
        />
      </div>
    )}
    <div className="text-center">
      <MarkdownRender content={step.content} />
    </div>
  </div>
);

const Steps: FunctionComponent<StepsProps> = ({
  title,
  subtitle,
  steps,
  button,
}) => (
  <div className="py-12">
    {title && (
      <div className="flex flex-col items-center mb-12">
        <ContentItemTitle
          title={title}
          subtitle={subtitle}
          inverted={true}
          subtitleColor={'blue'}
          titleColor={'navy'}
        />
        <Underline color={UNDERLINE_COLOR.blue} className={'-mt-8'} />
      </div>
    )}
    <div className="px-4 lg:hidden">
      <ContentCarousel>
        {steps.map((faq, i) => (
          <StepItem key={i} step={faq} index={i} />
        ))}
      </ContentCarousel>
    </div>
    <div className="hidden lg:max-w-4xl lg:m-auto lg:mb-4 lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-20">
      {steps.map((faq, i) => (
        <StepItem key={i} step={faq} index={i} />
      ))}
    </div>
    {button && button.Text && button.URL && (
      <div className="mt-8 mb-8 flex justify-center">
        <LinkButton cta={button.Text} url={button.URL} color={'navy'} />
      </div>
    )}
  </div>
);

export { Steps };
