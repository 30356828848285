import React, { FunctionComponent } from 'react';
import { ContentCarousel } from '@bunac/components/Programme/ContentCarousel';
import { IconContent } from '@bunac/components/Programme/Icon';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import { Image } from '@bunac/components/Programme/Image';

interface IconOffsetProps {
  icons: IconContent[];
}

interface IconProp {
  icon: IconContent;
  offset: boolean;
}

const IconView: FunctionComponent<IconProp> = ({ icon, offset }) => (
  <div className={`text-center ${offset ? 'lg:relative top-32' : ''}`}>
    <img src={icon.url} alt="Icon" className="block mx-auto mb-4" />
    {icon.content && (
      <MarkdownRender content={icon.content} className="mx-4 lg:mx-0" />
    )}
  </div>
);

const IconOffset: FunctionComponent<IconOffsetProps> = ({ icons }) => (
  <div className="mb-12 lg:flex flex-col items-center">
    <div className="lg:hidden">
      <ContentCarousel>
        {icons.map((icon, i) => (
          <IconView icon={icon} key={i} offset={false} />
        ))}
      </ContentCarousel>
    </div>
    <div className="hidden lg:grid lg:grid-cols-2 lg:max-w-4xl lg:gap-12 lg:mb-32">
      {icons.map((icon, i) => (
        <IconView icon={icon} offset={!(i % 2 === 0)} key={i} />
      ))}
    </div>
  </div>
);

export { IconOffset };
