import React, { FunctionComponent } from 'react';
import { Text } from '../../../components/Page/text';

interface ContentDisplayProps {
  title: string;
  titleNote?: string;
  spotlightTitle: string;
  description?: string;
  icon?: any;
  image: any;
  props?: any;
}

const ContentDisplay: FunctionComponent<ContentDisplayProps> = ({
  title,
  titleNote,
  spotlightTitle,
  description,
  icon,
  image,
  props
}) => (
  <>
    <div className='grid grid-cols-12 px-6 md:gap-x-8 md:relative mb-12 xl:p-16 w-full xl:w-11/12 mx-auto'>
      <img className='absolute -right-16 -top-24 invisible xl:visible z-10'
        src="/svg/why-bunac-blue-wash-right.svg"
        alt="Blue brush stroke positioned on the left top side"
        loading='lazy'
      />
      <div className='col-span-12 xl:col-span-6 z-10 flex h-72 md:h-96 m-auto'>
        <img className='rounded-3xl mx-auto object-cover' src={image.publicURL} loading='lazy'></img>
      </div>
      <div className='col-span-12 xl:col-span-5 flex z-10'>
        <div className='my-auto space-y-3 mt-6 xl:mt-0'>
          <h4 className='font-worksans text-2xl text-center xl:text-left text-teal'>{titleNote}</h4>
          <h1 className="font-worksans font-bold text-4xl text-center xl:text-left text-grey-dark">{title} <span className='text-blue'>{spotlightTitle}</span></h1>
          <div className="font-worksans text-sm text-center xl:text-left text-grey-dark">
            <Text {...props}>{description}</Text>
          </div>
        </div>
      </div>
      <div className='bg-grey_lightest hidden xl:block absolute inset-0 xl:left-32 xl:right-16 rounded-3xl'></div>
      <img className='hidden xl:block absolute -left-20 -bottom-40 xl:-bottom-64'
        src="/svg/why-bunac-grey-wash-left.svg"
        alt="Blue brush stroke positioned on the left top side"
        loading='lazy'
      />
    </div>
  </>
);

export { ContentDisplay };
