import React, { FunctionComponent } from 'react';

interface ModuleProps {
  children: React.ReactNode;
}

const Module: FunctionComponent<ModuleProps> = ({ children }) => (
  <div>{children}</div>
);

export { Module };
