import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from './text';

const Details = styled.details`
  border: 2px solid #000;
  max-width: 666px;
  border-collapse: collapse;
  margin-left: 32px;
  padding-left: 0;
  margin-right: 32px;
  padding-right: 0;
`;

const Question = styled.summary`
  background-color: #000;
  color: #fff;
  min-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
`;

const Answer = styled.div`
  padding: 12px;
`;

interface FaqItem {
  Question: string;
  Answer: string;
}

interface FaqProps {
  questions: FaqItem[];
}

const Faq: FunctionComponent<FaqProps> = ({ questions }) => (
  <>
    {questions.map((qa, index) => (
      <Details key={index}>
        <Question>{qa.Question}</Question>
        <Answer>
          <Text>{qa.Answer}</Text>
        </Answer>
      </Details>
    ))}
    <br />
  </>
);

export { Faq };
