import React, { FunctionComponent } from 'react';
import { ContentItem } from '@bunac/components/Programme/ContentItem';
import { FourUspGrid } from '@bunac/components/Programme/Modules/FourUspGrid';
import { FourUspOffset } from '@bunac/components/Programme/Modules/FourUspOffset';
import { ButtonDataSource } from '@bunac/components/Page/datasource';

enum ImageLayout {
  Grid = 'grid',
  Offset = 'offset',
}

interface FourUspProps {
  title: string;
  subtitle?: string;
  introduction?: string;
  items: ContentItem[];
  button?: ButtonDataSource;
  imageLayout: ImageLayout;
}

const FourUsp: FunctionComponent<FourUspProps> = (props) => {
  if (props.imageLayout === ImageLayout.Offset) {
    return <FourUspOffset {...props} />;
  }
  return <FourUspGrid {...props} />;
};

export { FourUsp };
