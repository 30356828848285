import React, { FunctionComponent } from 'react';
import { ButtonDataSource } from '@bunac/components/Page/datasource';
import { BasicColourAdBlock } from '@bunac/components/Programme/Modules/ColourAdBlockBasic';
import { FullColourAdBlock } from '@bunac/components/Programme/Modules/ColourAdBlockFull';
import { ThumbnailColourAdBlock } from '@bunac/components/Programme/Modules/ColourAdBlockThumbnail';
import { SmallThumbnailColourAdBlock } from '@bunac/components/Programme/Modules/ColourAdBlockSmallThumbnail';
import { ImageDataLike } from 'gatsby-plugin-image';

interface ColourAdBlockProps {
  title: string;
  subtitle: string;
  content?: string;
  button?: ButtonDataSource;
  image?: ImageDataLike;
  color: 'yellow' | 'blue' | 'navy' | 'pink' | 'transparent' | null;
  imageLayout: 'full' | 'thumbnail' | 'small' | 'none';
}

const ColourAdBlock: FunctionComponent<ColourAdBlockProps> = (props) => {
  if (props.color === 'transparent') {
    return <BasicColourAdBlock {...props} />;
  }

  if (props.imageLayout === 'small') {
    return <SmallThumbnailColourAdBlock {...props} />;
  }

  return props.imageLayout === 'full' ? (
    <FullColourAdBlock {...props} />
  ) : (
    <ThumbnailColourAdBlock {...props} />
  );
};

export { ColourAdBlock };
