import React, { FunctionComponent } from 'react';
import { Underline, UNDERLINE_COLOR } from '../Decoration/Underline';
import { IconContent } from '../Icon';
import { LinkButton } from '../LinkButton';
import { MarkdownRender } from '../MarkdownRender';
import { ContentItemTitle } from './ContentItemTitle';
import { IconGrid } from './IconGrid';
import { IconOffset } from './IconOffset';

interface TextWithIconsProps {
  title: string;
  subtitle: string;
  introduction: string;
  iconLayout: 'grid' | 'offset';
  icons: IconContent[];
  button?: { Text: string; URL: string };
}

const TextWithIcons: FunctionComponent<TextWithIconsProps> = ({
  title,
  subtitle,
  introduction,
  icons,
  iconLayout,
  button,
}) => {
  return (
    <div className="py-12 px-6">
      <div className="flex flex-col items-center">
        <ContentItemTitle
          title={title}
          subtitle={subtitle}
          titleColor={'navy'}
          subtitleColor={'blue'}
          bottomMargin={0}
          inverted={true}
        />
        {title && <Underline color={UNDERLINE_COLOR.blue} className={'mb-8'} />}
        {introduction && (
          <MarkdownRender
            content={introduction}
            className="text-center lg:max-w-2xl mb-8"
          />
        )}
      </div>
      {iconLayout === 'grid' && <IconGrid icons={icons} />}
      {iconLayout === 'offset' && <IconOffset icons={icons} />}
      <div className="flex flex-col items-center">
        {button && button.Text && button.URL && (
          <div className="">
            <LinkButton cta={button.Text} url={button.URL} color={'pink'} />
          </div>
        )}
      </div>
    </div>
  );
};

export { TextWithIcons };
