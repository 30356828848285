import React, { FunctionComponent } from 'react';
import { ContentCarousel } from '../ContentCarousel';
import { Underline, UNDERLINE_COLOR } from '../Decoration/Underline';
import { LinkButton } from '../LinkButton';
import { Location } from '../Location';
import { MarkdownRender } from '../MarkdownRender';
import { ContentItemTitle } from './ContentItemTitle';
import { Image } from '@bunac/components/Programme/Image';

interface FiveImageProps {
  title: string;
  subtitle: string;
  introduction: string;
  locations: Location[];
}

interface LocationProps {
  location: Location;
  offset?: boolean;
}

const LocationItem: FunctionComponent<LocationProps> = ({
  location,
  offset = false,
}) => (
  <div className={`pb-8 relative xl:w-52 xl:ml-3 ${offset ? 'xl:top-12' : ''}`}>
    <Image
      className="rounded-3xl xl:w-full"
      image={location.image}
      alt={location.camp}
    />
    <div
      className={`relative ${
        location.button && location.button.text
          ? 'bottom-24 -mb-24'
          : 'bottom-12 -mb-12'
      } rounded-3xl bg-blue text-white text-center mr-3 ml-3 p-4 px-6 xl:px-4`}
    >
      <h4 className={'font-worksans text-lg font-light mb-2'}>
        {location.camp}
      </h4>
      <p
        className={`${
          location.button && location.button.text ? 'mb-4' : 'mb-0'
        }`}
      >
        {location.location}
      </p>
      {location.button && location.button.text && location.button.url && (
        <div className="flex items-center justify-center mt-3">
          <LinkButton
            cta={location.button.text}
            url={location.button.url}
            color={'pink'}
          />
        </div>
      )}
    </div>
  </div>
);

const FiveImage: FunctionComponent<FiveImageProps> = ({
  title,
  subtitle,
  introduction,
  locations,
}) => (
  <div className="py-12 px-4">
    <div className="flex flex-col items-center">
      {title && (
        <>
          <ContentItemTitle
            title={title}
            subtitle={subtitle}
            inverted={true}
            titleColor={'navy'}
            subtitleColor={'blue'}
            bottomMargin={0}
          />
          <Underline color={UNDERLINE_COLOR.blue} className={'mb-8'} />
        </>
      )}
      {introduction && (
        <div className="mb-8 lg:max-w-xl text-center">
          <MarkdownRender content={introduction} />
        </div>
      )}
    </div>
    <div className="md:hidden">
      <ContentCarousel>
        {locations.map((location, i) => (
          <LocationItem location={location} key={i} />
        ))}
      </ContentCarousel>
    </div>
    <div className="hidden md:block xl:hidden">
      <ContentCarousel itemsToShow={3}>
        {locations.map((location, i) => (
          <LocationItem location={location} key={i} />
        ))}
      </ContentCarousel>
    </div>
    <div className="hidden xl:flex xl:items-center xl:pt-8 xl:justify-center xl:mb-12">
      {locations.map((location, i) => (
        <LocationItem location={location} key={i} offset={!(i % 2 === 0)} />
      ))}
    </div>
  </div>
);

export { FiveImage };
