import React, { FunctionComponent } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import GalleryList, { GalleryItem } from '../Gallery';
import Teaser, { TeaserItem } from '../Teaser';

interface GalleryProps {
  gallery: any;
  theme?: any;
}

const Gallery: FunctionComponent<GalleryProps> = ({ gallery, theme }) => {
  const strapiGalleries = useStaticQuery(graphql`
    query GalleryQuery {
      allStrapiGalleries {
        edges {
          node {
            id
            strapiId
            Title
            Carousel
            Teasers {
              Title
              Intro
              Button_Text
              Button_URL
              Key_Visual {
                childImageSharp {
                  gatsbyImageData(
                    width: 320
                    height: 230
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const strapiGallery = strapiGalleries.allStrapiGalleries.edges
    .map((elem: any) => elem.node)
    .find((elem: any) => elem.strapiId === gallery.id);

  if (!strapiGallery) {
    return <></>;
  }
  const teasers = strapiGallery.Teasers;

  if (strapiGallery.Carousel) {
    return (
      <GalleryList>
        {teasers.map((teaser: any) => (
          <GalleryItem
            key={teaser.id}
            className={`theme-${theme}`}
            link={teaser.Button_URL}
            linkText={teaser.Button_Text}
            title={teaser.Title}
            thumbnail={teaser.Key_Visual}
            description={teaser.Intro}
          />
        ))}
        <Grid item={true} xs={12} />
      </GalleryList>
    );
  }

  return (
    <Teaser fullWidth={true} alignItems={'stretch'}>
      {teasers.map((teaser: any) => (
        <TeaserItem
          key={teaser.id}
          className={`theme-${theme}`}
          link={teaser.Button_URL}
          linkText={teaser.Button_Text}
          title={teaser.Title}
          thumbnail={teaser.Key_Visual}
          description={teaser.Intro}
        />
      ))}
      <Grid item={true} xs={12} />
    </Teaser>
  );
};

export { Gallery };
