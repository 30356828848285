import React from 'react';

const LeftBurst = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="172.082"
    height="222.658"
    viewBox="0 0 172.082 222.658"
    className="burst burst-left"
  >
    <g id="decoration" transform="translate(-838.065 319.792) rotate(-22)">
      <path
        id="Path_3430"
        data-name="Path 3430"
        d="M917.133,155.947c8.89,4.767,17.459,9.286,25.944,13.947,8.688,4.775,17.417,9.5,25.86,14.679,2.189,1.34,3.385,4.288,5.043,6.5-2.6.093-5.652,1.13-7.708.122-12.031-5.869-23.706-12.486-35.772-18.282-5.058-2.431-11.039-2.945-16.1-5.368-2.154-1.031-4.274-4.854-4.044-7.176C910.515,158.718,914.635,157.462,917.133,155.947Z"
        transform="translate(-7.109 -10.154)"
        fill="#ffe59a"
        fill-rule="evenodd"
      />
      <path
        id="Path_3432"
        data-name="Path 3432"
        d="M1025.92,172.476c-7.6-.749-9.075-5.92-8.121-11.087,1.806-9.739,4.745-19.268,7.175-28.888,1.183-4.663,2.431-9.315,3.412-14.025,1.2-5.721,4.9-7.673,10.039-6.2,5.879,1.687,2.663,6.15,1.659,9.164-3.831,11.548-8.441,22.843-12.06,34.449C1026.475,160.857,1026.638,166.35,1025.92,172.476Z"
        transform="translate(-20.137 -4.79)"
        fill="#ffe59a"
        fill-rule="evenodd"
      />
      <path
        id="Path_3433"
        data-name="Path 3433"
        d="M954.7,227.426c-4.029,7.636-12.352,5.184-18.676,7.352a194.11,194.11,0,0,1-21.831,6.282,16.162,16.162,0,0,1-9.283-1.357c-1.406-.619-2.966-4.652-2.322-5.47,1.7-2.161,4.407-4.48,6.98-4.847,14.732-2.1,29.547-3.654,44.327-5.393C954.159,225.139,954.432,226.283,954.7,227.426Z"
        transform="translate(-6.149 -18.423)"
        fill="#ff808b"
        fill-rule="evenodd"
      />
      <path
        id="Path_3438"
        data-name="Path 3438"
        d="M860.853,115.944c4.106-.6,7.9-2.413,10.219-1.263,7.051,3.5,13.6,8.045,20.16,12.466a4.411,4.411,0,0,1,1.035,4.192c-.657,1.175-3.169,2.589-4.043,2.165-8.345-3.987-16.536-8.3-24.692-12.672C862.721,120.4,862.455,118.944,860.853,115.944Z"
        transform="translate(-1.095 -5.093)"
        fill="#ffe59a"
        fill-rule="evenodd"
      />
      <path
        id="Path_3439"
        data-name="Path 3439"
        d="M1048.451,72.462c5.735-.636,8.749,3.361,6.924,8.873-1.145,3.452-1.571,7.147-2.818,10.548-.778,2.138-2.494,3.935-3.79,5.885-2.119-1.536-5.073-2.617-6.217-4.693C1039.406,87.329,1043.859,72.977,1048.451,72.462Z"
        transform="translate(-23.048)"
        fill="#ffe59a"
        fill-rule="evenodd"
      />
      <path
        id="Path_3440"
        data-name="Path 3440"
        d="M990.122,151.524c-3.071-5.526-6.784-10.822-8.849-16.7-.692-1.957,2.847-5.4,4.456-8.163,2.351,2.158,5.541,3.91,6.813,6.579,1.511,3.186,1.708,7.145,1.819,10.79.062,2.346-1.063,4.728-1.651,7.1C991.843,151.26,990.989,151.389,990.122,151.524Z"
        transform="translate(-15.719 -6.595)"
        fill="#ff808b"
        fill-rule="evenodd"
      />
      <path
        id="Path_3441"
        data-name="Path 3441"
        d="M863.12,241.776c1.965-.168,4.232-1.024,5.834-.336,2.217.958,3.924,3.078,5.855,4.7-1.791,1.469-3.387,3.383-5.428,4.3a30.932,30.932,0,0,1-8.828,2.413c-2.154.263-5.078.227-6.478-1.012-1.5-1.327-2.742-4.738-2.028-6.284.784-1.7,3.834-2.592,6.072-3.315,1.51-.494,3.315-.095,4.994-.095C863.113,242.026,863.113,241.9,863.12,241.776Z"
        transform="translate(0 -20.512)"
        fill="#ff808b"
        fill-rule="evenodd"
      />
      <path
        id="Path_3443"
        data-name="Path 3443"
        d="M918.34,277.178c7.561-.6,15.06-1.186,22.678-1.783-.692,7.074-9.213,13.322-16.39,11.835C920.194,286.31,916.095,283.695,918.34,277.178Z"
        transform="translate(-8.004 -24.67)"
        fill="#ffe59a"
        fill-rule="evenodd"
      />
    </g>
  </svg>
);

const RightBurst = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="142.291"
    height="197.143"
    viewBox="0 0 142.291 197.143"
    className="burst burst-right"
  >
    <g id="decoration" transform="translate(-831.759 597.147) rotate(-41)">
      <path
        id="Path_3428"
        data-name="Path 3428"
        d="M1069.206,212.124c2.006-1.707,3.781-3.848,6.052-5.051,13.353-7.081,27.553-10.409,42.674-8.392a9.3,9.3,0,0,1,5.647,3.548c.545.763-.971,3.8-2.3,4.878-1.09.887-3.328.7-4.989.5-14.086-1.723-27.644-.208-40.6,5.919-1.607.761-3.743.394-5.63.551C1069.772,213.424,1069.493,212.774,1069.206,212.124Z"
        transform="translate(-70.146 -40.566)"
        fill="#ffe59a"
        fill-rule="evenodd"
      />
      <path
        id="Path_3429"
        data-name="Path 3429"
        d="M1031.071,272.285c1.682,1.34,3.586,2.238,4.562,3.738q11.819,18.161,23.238,36.58c.836,1.346.28,3.546.373,5.35-1.834.018-4.282.8-5.388-.087a32.023,32.023,0,0,1-7.793-8.324c-5.474-9.419-10.5-19.1-15.511-28.786a22.5,22.5,0,0,1-1.693-6.609C1028.8,273.755,1030.014,273.149,1031.071,272.285Z"
        transform="translate(-57.125 -64.506)"
        fill="#ffe59a"
        fill-rule="evenodd"
      />
      <path
        id="Path_3431"
        data-name="Path 3431"
        d="M1129.238,272.793c-2.637.828-5.294,2.668-6.976,2.012a138.591,138.591,0,0,1-18.051-8.707c-6.734-3.844-12.4-9.783-21.141-9.3-1.28.07-2.689-2.15-4.031-3.312,1.464-1.017,3.131-3.1,4.349-2.862,5.674,1.109,11.682,1.974,16.71,4.581C1109.538,260.1,1118.379,266.144,1129.238,272.793Z"
        transform="translate(-73.319 -57.511)"
        fill="#ff808b"
        fill-rule="evenodd"
      />
      <path
        id="Path_3434"
        data-name="Path 3434"
        d="M1000.781,301.664a39.64,39.64,0,0,1-2.524,5.854c-4.211,6.606-5.069,13.164-1.455,20.478,1.089,2.211.075,5.46.021,8.237-2.707-1.607-6.439-2.562-7.938-4.932-5-7.93-2.373-22.074,4.664-27.917,1.672-1.386,3.846-2.168,5.793-3.231C999.82,300.658,1000.3,301.163,1000.781,301.664Z"
        transform="translate(-43.391 -73.5)"
        fill="#ff808b"
        fill-rule="evenodd"
      />
      <path
        id="Path_3435"
        data-name="Path 3435"
        d="M1186.867,301.152c-1.031,4.374-4.612,5.509-8.368,4.182a36,36,0,0,1-10.5-6.2c-2.38-1.947-4.053-5.139-.417-7.683,2.969-2.079,5.9-3.3,9.081.561C1179.474,295.42,1183.276,298.007,1186.867,301.152Z"
        transform="translate(-101.21 -70.063)"
        fill="#ff808b"
        fill-rule="evenodd"
      />
      <path
        id="Path_3436"
        data-name="Path 3436"
        d="M1091.591,144.954a72.546,72.546,0,0,1-5.112,5.209c-4.813,3.978-9.619,7.986-14.694,11.615-1.621,1.161-4.068,1.169-6.138,1.7.512-1.626.651-3.525,1.6-4.833C1077,145.238,1079.695,143.689,1091.591,144.954Z"
        transform="translate(-68.997 -23.3)"
        fill="#ff808b"
        fill-rule="evenodd"
      />
      <path
        id="Path_3437"
        data-name="Path 3437"
        d="M1070.347,364.44c7.34-1.323,10.947.024,10.138,7.332-.242,2.178,2.093,4.625,1.909,6.838-.188,2.268-2.016,4.4-3.122,6.591-1.99-1.23-5.247-2.087-5.744-3.76C1071.943,376.079,1071.382,370.413,1070.347,364.44Z"
        transform="translate(-70.514 -94.101)"
        fill="#ffe59a"
        fill-rule="evenodd"
      />
      <path
        id="Path_3442"
        data-name="Path 3442"
        d="M1179.2,198.661c5.376.417,10.786.643,16.1,1.461.788.122,1.143,3.04,1.693,4.662-4.088.736-8.273,2.31-12.241,1.9-2.535-.261-4.773-3.46-7.141-5.342Q1178.4,200,1179.2,198.661Z"
        transform="translate(-105.129 -40.747)"
        fill="#ffe59a"
        fill-rule="evenodd"
      />
      <path
        id="Path_3444"
        data-name="Path 3444"
        d="M998.4,382.308c-3.176,2.953-4.8,4.46-6.423,5.97-1.812-2.373-3.975-4.587-5.177-7.237-.3-.659,2.9-4.129,3.85-3.878C992.944,377.759,994.853,379.8,998.4,382.308Z"
        transform="translate(-43.547 -98.347)"
        fill="#ff808b"
        fill-rule="evenodd"
      />
    </g>
  </svg>
);

export { LeftBurst, RightBurst };
