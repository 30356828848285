import React, { FunctionComponent } from 'react';
import { ContentItem } from '@bunac/components/Programme/ContentItem';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { ButtonDataSource } from '@bunac/components/Page/datasource';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import { ContentCarousel } from '@bunac/components/Programme/ContentCarousel';
import { Image } from '@bunac/components/Programme/Image';

interface FourUspProps {
  title: string;
  subtitle?: string;
  introduction?: string;
  items: ContentItem[];
  button?: ButtonDataSource;
}

interface ContentItemProp {
  item: ContentItem;
}

const ContentItemView: FunctionComponent<ContentItemProp> = ({ item }) => (
  <div className="md:mb-8">
    {item.image && (
      <div className="filter drop-shadow-xl m-auto block object-cover w-36 h-36">
        <Image
          image={item.image}
          alt={item.title}
          className="rounded-full border-white border-4 border-solid"
        />
      </div>
    )}
    <div className="flex flex-col items-center">
      <h4 className="text-blue text-xl font-worksans font-medium text-center mt-6 mb-3">
        {item.title}
      </h4>
      <div className="font-worksans text-sm text-center md:max-w-md">
        <MarkdownRender content={item.text} />
      </div>
    </div>
  </div>
);

const FourUspGrid: FunctionComponent<FourUspProps> = ({
  title,
  subtitle,
  introduction,
  button,
  items,
}) => (
  <div className="px-6 pt-8 pb-4 mx-auto">
    <div className="flex flex-col items-center space-x-4">
      <ContentItemTitle title={title} subtitle={subtitle} bottomMargin={2} />
      {introduction && (
        <div className="font-worksans text-sm text-center md:max-w-2xl">
          <MarkdownRender content={introduction} />
        </div>
      )}
      {button?.Text && button?.URL && (
        <div className="mt-4 mb-12">
          <LinkButton cta={button.Text} url={button.URL} color={'pink'} />
        </div>
      )}
    </div>
    <div className="mb-8 md:hidden">
      <ContentCarousel>
        {items.map((item, i) => (
          <ContentItemView key={i} item={item} />
        ))}
      </ContentCarousel>
    </div>
    <div className="hidden px-2 md:grid md:grid-cols-2 md:gap-20 md:max-w-5xl md:mx-auto">
      {items.map((item, i) => (
        <ContentItemView key={i} item={item} />
      ))}
    </div>
  </div>
);

export { FourUspGrid };
