import React, { FunctionComponent } from 'react';
import { ContentItem } from '@bunac/components/Programme/ContentItem';
import { ButtonContent } from '@bunac/components/Programme/Button';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { Underline } from '@bunac/components/Programme/Decoration/Underline';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';

interface SideBySideProps {
  title?: string;
  subtitle?: string;
  introduction?: string;
  item1: ContentItem;
  item2: ContentItem;
  button?: ButtonContent;
}

interface ContentItemViewProps {
  item: ContentItem;
  titleColor: 'blue' | 'navy';
}

const ContentItemView: FunctionComponent<ContentItemViewProps> = ({
  item,
  titleColor,
}) => (
  <div className="mb-8 md:flex-1">
    {item.title && (
      <ContentItemTitle
        title={item.title}
        subtitle={item.subtitle}
        bottomMargin={2}
        titleColor={'navy'}
        subtitleColor={titleColor}
        inverted={true}
      />
    )}
    <div className="text-center">
      <MarkdownRender content={item.text} alternative={true} />
    </div>
    {item.button && item.button.url && (
      <div className="flex justify-center mt-3">
        <LinkButton cta={item.button.text} url={item.button.url} color="pink" />
      </div>
    )}
  </div>
);

const SideBySideBasic: FunctionComponent<SideBySideProps> = ({
  title,
  subtitle,
  introduction,
  item1,
  item2,
  button,
}) => (
  <div className="flex flex-col items-center px-6 py-8 md:max-w-2xl md:mx-auto font-worksans">
    {title && (
      <ContentItemTitle
        title={title}
        subtitle={subtitle}
        bottomMargin={2}
        titleColor="navy"
        subtitleColor="blue"
        inverted={true}
      />
    )}
    {title && <Underline />}
    {introduction && (
      <MarkdownRender
        content={introduction}
        className="text-center font-worksans my-8 px-4"
      />
    )}
    <div className="md:flex md:space-x-14">
      <ContentItemView item={item1} titleColor={'blue'} />
      <ContentItemView item={item2} titleColor={'navy'} />
    </div>
    {button && button.text && button.url && (
      <div className="my-8">
        <LinkButton cta={button.text} url={button.url} color={'pink'} />
      </div>
    )}
  </div>
);

export { SideBySideBasic };
