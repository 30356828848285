import React, { FunctionComponent } from 'react';
import { ContentItem } from '@bunac/components/Programme/ContentItem';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { ButtonDataSource } from '@bunac/components/Page/datasource';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';
import { Image } from '@bunac/components/Programme/Image';

interface FourUspProps {
  title: string;
  subtitle?: string;
  introduction?: string;
  cta?: string;
  url?: string;
  items: ContentItem[];
  button?: ButtonDataSource;
}

interface ContentItemProp {
  item: ContentItem;
  titleColor: 'navy' | 'blue';
  mobileTitleColor: 'navy' | 'blue';
  backgroundColor: 'bg-navy' | 'bg-blue';
  mobileBackgroundColor: 'bg-navy' | 'bg-blue';
  offset: boolean;
}

interface ContentItemTitleProps {
  item: ContentItem;
  titleColor: 'navy' | 'blue';
  mobileTitleColor: 'navy' | 'blue';
}

const ContentItemTitleView: FunctionComponent<ContentItemTitleProps> = ({
  item,
  titleColor,
  mobileTitleColor,
}) => (
  <div className="flex flex-col items-center">
    {item.title && (
      <ContentItemTitle
        title={item.title}
        subtitle={item.subtitle}
        bottomMargin={0}
        titleMargin={'-mb-2'}
        titleColor={titleColor}
        mobileTitleColor={mobileTitleColor}
        subtitleColor={'white'}
        inverted={false}
        bold={true}
      />
    )}
    {item.title && item.subtitle && (
      <>
        <Underline
          className={'hidden md:block mb-8'}
          color={
            titleColor === 'blue' ? UNDERLINE_COLOR.blue : UNDERLINE_COLOR.navy
          }
        />
        <Underline
          className={'md:hidden mb-8'}
          color={
            mobileTitleColor === 'blue'
              ? UNDERLINE_COLOR.blue
              : UNDERLINE_COLOR.navy
          }
        />
      </>
    )}
  </div>
);

const ContentItemView: FunctionComponent<ContentItemProp> = ({
  item,
  titleColor,
  mobileTitleColor,
  backgroundColor,
  mobileBackgroundColor,
  offset,
}) => (
  <div
    className={`relative flex flex-1 flex-col lg:max-w-md ${
      item.image ? '' : 'mb-8'
    } ${offset ? 'lg:top-20' : ''}`}
  >
    {item.image && (
      <div className="relative ml-6 mr-6 z-50 lg:mx-8">
        <Image
          className="rounded-3xl m-auto"
          image={item.image}
          alt={item.title}
        />
      </div>
    )}
    <div
      className={`relative ${
        item.image ? '-top-24 -mb-16 pt-28' : 'pt-6'
      } rounded-3xl px-6 pb-6  z-1 ${mobileBackgroundColor} md:${backgroundColor} md:px-8`}
    >
      <ContentItemTitleView
        item={item}
        titleColor={titleColor}
        mobileTitleColor={mobileTitleColor}
      />
      <div className="text-center font-worksans text-white text-sm mb-8">
        <MarkdownRender content={item.text} />
      </div>
      {item.button && item.button.url && (
        <div className="flex justify-center mt-3 mb-4">
          <LinkButton
            cta={item.button.text}
            url={item.button.url}
            color="blue"
          />
        </div>
      )}
    </div>
  </div>
);

const FourUspOffset: FunctionComponent<FourUspProps> = ({
  title,
  subtitle,
  introduction,
  items,
  button,
}) => (
  <div className="px-6 pt-12 pb-24 mx-auto flex flex-col items-center md:space-x-4">
    {title && (
      <>
        <ContentItemTitle
          title={title}
          subtitle={subtitle}
          bottomMargin={0}
          inverted={true}
          titleColor={'navy'}
          subtitleColor={'blue'}
        />
        <Underline color={UNDERLINE_COLOR.blue} className="mb-10" />
      </>
    )}
    {introduction && (
      <MarkdownRender
        content={introduction}
        className="font-worksans text-sm text-center md:max-w-2xl"
      />
    )}
    <div className="md:grid md:grid-cols-2 md:gap-12">
      {items.map((item, i) => (
        <ContentItemView
          key={i}
          item={item}
          titleColor={i === 0 || i === 3 ? 'blue' : 'navy'}
          mobileTitleColor={i % 2 === 0 ? 'blue' : 'navy'}
          backgroundColor={i === 0 || i === 3 ? 'bg-navy' : 'bg-blue'}
          mobileBackgroundColor={i % 2 === 0 ? 'bg-navy' : 'bg-blue'}
          offset={i === 1 || i === 3}
        />
      ))}
    </div>
    {button?.Text && button?.URL && (
      <div className="mt-4 mb-12 lg:mt-20">
        <LinkButton cta={button.Text} url={button.URL} color={'pink'} />
      </div>
    )}
  </div>
);

export { FourUspOffset };
