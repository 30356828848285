import React, { FunctionComponent } from 'react';

interface ContentItemTitleProps {
  title: string;
  subtitle?: string;
  bottomMargin?: number;
  inverted?: boolean;
  titleSize?: string;
  subtitleSize?: string;
  titleMargin?: string;
  bold?: boolean;
  titleColor?: 'blue' | 'navy' | 'yellow' | 'white' | 'grey-dark';
  mobileTitleColor?: 'blue' | 'navy' | 'yellow' | 'white';
  subtitleColor?: 'blue' | 'navy' | 'white' | 'grey-dark';
  textAlignment?: 'text-center' | 'text-left';
  textFont?: string;
}

const FONT_TITLE = 'font-fave font-bold mb-2';
const SUBTITLE_FONT = 'font-worksans font-light';

export const ContentItemTitle: FunctionComponent<ContentItemTitleProps> = ({
  title,
  subtitle,
  mobileTitleColor,
  bottomMargin = 8,
  inverted = false,
  titleColor = 'blue',
  subtitleColor = 'navy',
  titleSize = 'text-6xl',
  titleMargin = 'mb-2',
  subtitleSize = 'text-2xl',
  textAlignment = 'text-center',
  bold = false,
  textFont = FONT_TITLE,
}) => (
  <header className={`${textAlignment} mb-${bottomMargin}`}>
    {title && subtitle && (
      <>
        <h3 className={`flex-none font-medium ${textAlignment} mt-3 mb-3`}>
          <span className={`block text-${
              mobileTitleColor ? mobileTitleColor : titleColor
            } md:text-${titleColor} ${inverted ? subtitleSize : titleSize} ${
              inverted ? SUBTITLE_FONT : textFont
            }
              ${inverted ? '' : titleMargin}
            `}
          >
            {title}
          </span>{' '}
          <span
            className={`block text-${subtitleColor} ${
              inverted ? titleSize : subtitleSize
            } ${inverted ? textFont : SUBTITLE_FONT}
              ${inverted ? '' : titleMargin}
            `}
          >
            {subtitle}
          </span>
        </h3>
      </>
    )}
    {title && !bold && !subtitle && (
      <h3 className={`flex-none ${textAlignment} mt-3 mb-3 ${SUBTITLE_FONT}`}>
        <span className={`block text-${subtitleColor}`}>{title}</span>
      </h3>
    )}
    {title && bold && !subtitle && (
      <h3 className={`flex-none ${textAlignment} mt-3 mb-3`}>
        <span
          className={`block text-${
            mobileTitleColor ? mobileTitleColor : titleColor
          } md:text-${titleColor} ${titleSize} ${textFont}`}
        >
          {title}
        </span>
      </h3>
    )}
  </header>
);
