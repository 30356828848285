import React, { FunctionComponent } from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import {
  Underline,
  UNDERLINE_COLOR,
} from '@bunac/components/Programme/Decoration/Underline';
import { MarkdownRender } from '@bunac/components/Programme/MarkdownRender';
import {
  ButtonDataSource,
  JobRoleDataSource,
} from '@bunac/components/Page/datasource';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import {
  JobRole,
  JobRoleAlternative,
} from '@bunac/components/Programme/Modules/JobRole';

interface JobRolesProps {
  title: string;
  subtitle: string;
  introduction: string;
  roles: JobRoleDataSource[];
  button?: ButtonDataSource;
}

const JobRoles: FunctionComponent<JobRolesProps> = ({
  title,
  subtitle,
  introduction,
  roles,
  button,
}) => (
  <div className="py-12">
    <div className="flex flex-col items-center">
      {title && (
        <>
          <ContentItemTitle
            title={title}
            subtitle={subtitle}
            inverted={true}
            titleColor={'navy'}
            subtitleColor={'blue'}
            bottomMargin={0}
          />
          <Underline color={UNDERLINE_COLOR.blue} className={'mb-8'} />
        </>
      )}
      {introduction && (
        <div className="mb-8 lg:max-w-xl text-center">
          <MarkdownRender content={introduction} />
        </div>
      )}
      {roles.map((role, i) =>
        i % 2 === 0 ? (
          <JobRole key={i} role={role} />
        ) : (
          <JobRoleAlternative key={i} role={role} />
        )
      )}
      {button && button?.Text && button?.URL && (
        <LinkButton cta={button.Text} url={button.URL} color={'pink'} />
      )}
    </div>
  </div>
);

export { JobRoles };
