import React, { FunctionComponent } from 'react';

interface WoodProps {
  children: React.ReactNode;
}

const Wood: FunctionComponent<WoodProps> = ({ children }) => (
  <div className="wood-top">
    <div className="wood-bottom">
      <div className="wood">{children}</div>
    </div>
  </div>
);

const WoodTop: FunctionComponent<WoodProps> = ({ children }) => (
  <div className="wood-top top-only">
    <div className="wood top-only">{children}</div>
  </div>
);

const WoodBottom: FunctionComponent<WoodProps> = ({ children }) => (
  <div className="wood-bottom bottom-only">
    <div className="wood bottom-only">{children}</div>
  </div>
);

const WoodHalf: FunctionComponent<WoodProps> = ({ children }) => (
  <div className="background-half">
    <div className="wood-half" />
    {children}
  </div>
);

export { Wood, WoodTop, WoodBottom, WoodHalf };
