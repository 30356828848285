import React, { FunctionComponent } from 'react';
import { BoxItem } from '../BoxItem';

interface BoxContentProps {
    items: BoxItem[];
}

const BoxContent: FunctionComponent<BoxContentProps> = ({
    items,
}) => (
    <div className='relative items-center text-center px-6 md:max-w-4xl mx-auto mt-8 md:mt-18 mb-8 md:mb-18'>
      <div className='relative space-y-10 md:space-y-0 md:grid md:grid-cols-12 gap-10 mx-auto z-10'>
      {items.map((data, i) => (
        <div onClick={()=> window.location.href=data.link} className='relative md:col-span-4 h-44 md:h-56 rounded-3xl overflow-hidden transition-all shadow-lg cursor-pointer'
          key={i}>
          <h1 className='z-20 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl md:text-lg mb-2 font-bold text-white'>{data.title}</h1>
          <div className='absolute inset-0 bg-black opacity-25'></div>
          <img className="h-full w-full object-cover" src={data.image}></img>
        </div>
      ))}
      </div>
      <img
        className="absolute -left-52 bottom-12"
        src="/svg/grey-wash-left-02.svg"
        alt="Grey brush stroke positioned on the left side"
      />
      <img
        className="absolute -right-52 top-0"
        src="/svg/orange-wash-right.svg"
        alt="Orange brush stroke positioned on the right bottom side"
      />
    </div>
);

export { BoxContent };
