import React, { FunctionComponent } from 'react';
import { Text } from './text';
import { Faq } from './faq';
import { StepList } from './steps';
import { Gallery } from './gallery';
import { FAQs } from '@bunac/components/Programme/Modules/FAQ';
import { FourUsp } from '@bunac/components/Programme/Modules/FourUsp';
import {
  ContentItemDataSource,
  QuoteDataSource,
  blogsItemMapper,
  contentItemMapper,
  FaqDataSource,
  BlogCategoryDataSource,
  StepsDataSource,
  locationMapper,
  JobRoleDataSource,
  ProductsDataSource,
  productDataMapper,
  IconDataSource,
  QuoteTabDataSource,
  RedirectItemDataSource,
  BoxItemDataSource,
  redirectItemMapper,
  boxItemMapper,
} from './datasource';
import { TextAndVideo } from '@bunac/components/Programme/Modules/TextAndVideo';
import { Steps as StepsModule } from '@bunac/components/Programme/Modules/Steps';
import { JobRoles as JobRolesModule } from '@bunac/components/Programme/Modules/JobRoles';
import { ColourAdBlock } from '@bunac/components/Programme/Modules/ColourAdBlock';
import { Quotes as QuotesList } from '@bunac/components/Programme/Modules/Quotes';
import { SideBySide } from '@bunac/components/Programme/Modules/SideBySide';
import { Grid } from '@bunac/components/Programme/Modules/Grid';
import { Stacked } from '@bunac/components/Programme/Modules/Stacked';
import { AlternateHighlights } from '@bunac/components/Programme/Modules/AlternateHighlights';
import { Blogs as BlogsList } from '@bunac/components/Programme/Modules/Blogs';
import { BlogsV2 as BlogsListV2 } from '@bunac/components/Programme/Modules/BlogsV2';
import { TravelConfidence } from '@bunac/components/Programme/Modules/TravelConfidence';
import { ProductGroup } from '@bunac/components/Programme/Modules/ProductGroup';
import { CTA } from '@bunac/components/Programme/Modules/CTA';
import { Banner } from '@bunac/components/Programme/Modules/Banner';
import { Reviews } from '@bunac/components/Programme/Modules/Reviews';
import {
  Wood,
  WoodTop,
  WoodBottom,
  WoodHalf,
} from '@bunac/components/Programme/Decoration/Wooden';
import {
  Paper,
  PaperTop,
  PaperBottom,
  PaperHalf,
} from '@bunac/components/Programme/Decoration/Paper';
import {
  PaperLight,
  PaperLightTop,
  PaperLightBottom,
  PaperLightHalf,
} from '@bunac/components/Programme/Decoration/PaperLight';
import { Module } from '@bunac/components/Programme/Decoration/Module';
import { BrushStroke } from '@bunac/components/Programme/Decoration/BrushStroke';
import { ThreeUsp } from '@bunac/components/Programme/Modules/ThreeUsp';
import { RestrictedContent } from '@bunac/components/Programme/Modules/RestrictedContent';
import { TextAndQuote } from '@bunac/components/Programme/Modules/TextAndQuotes';
import { Text as EnhancedText } from '@bunac/components/Programme/Modules/Text';
import { FiveImage } from '@bunac/components/Programme/Modules/FiveImage';
import { TextWithButtons } from '@bunac/components/Programme/Modules/TextAndButtons';
import { TextWithIcons } from '@bunac/components/Programme/Modules/TextAndIcons';
import { TabbedQuotes } from '@bunac/components/Programme/Modules/TabbedQuotes';
import { ContentDisplay } from '../Programme/Modules/ContentDisplay';
import { RedirectContent } from '../Programme/Modules/RedirectContent';
import { BoxContent } from '../Programme/Modules/BoxContent';

export { Text, Faq, Gallery, StepList };

type LayoutVersion = 'V1' | 'V2';

interface PageProps {
  content: any;
  layoutVersion?: LayoutVersion;
  roles?: JobRoleDataSource[];
  categories?: BlogCategoryDataSource[];
  products?: ProductsDataSource[];
}

const Page: FunctionComponent<PageProps> = ({
  content,
  layoutVersion = 'V1',
  roles,
  categories,
  products,
  ...props
}) => {
  const {
    Title,
    Subtitle,
    Content,
    Questions,
    Quotes,
    TextQuotes,
    Galleries,
    Blogs,
    Layout,
    Steps,
    Introduction,
    Button,
    Buttons,
    Icons,
    IconLayout,
    Items,
    Item1,
    Item2,
    Product_One,
    Product_Two,
    Restriction,
    YouTube_URL,
    ColorVariant,
    Locations,
    Colour,
    ImageLayout,
    Key_Visual,
    JobRoles,
    Quote_Tabs,
    ButtonText,
    Travel_With_Confidence_Title,
    ReviewsTitle,
    ReviewsSubtitle,
    Box_Item,
    Redirect_Title,
    Redirect_Content,
    Redirect_Item,
    Banner_URL,
    Banner_Image,
    TitleNote,
    SpotlightTitle,
    TitleContent,
    Description,
    ContentDisplayImage,
    Icon,
    Grid_Items,
    ContentLayout,
    BackgroundType,
    Decoration,
    SideBySideDisplay,
    Brush,
    URL,
  } = content;

  let component = <></>;
  let title = <></>;

  if (Title && layoutVersion === 'V1') {
    title = <h1>{Title}</h1>;
  }

  if (Content && layoutVersion === 'V1') {
    component = <Text {...props}>{Content}</Text>;
  }

  if (Steps && layoutVersion === 'V1') {
    component = <StepList {...props} steps={Steps} />;
  }

  if (Questions && layoutVersion === 'V1') {
    component = <Faq {...props} questions={Questions} />;
  }

  if (Galleries && layoutVersion === 'V1') {
    component = Galleries.map((gallery: any) => (
      <Gallery {...props} key={gallery.id} gallery={gallery} />
    ));
  }

  if (Title && layoutVersion === 'V2') {
    component = (
      <EnhancedText
        title={Title}
        subtitle={Subtitle}
        content={Content}
        button={Button}
        decoration={Decoration}
      />
    );
  }

  if (Title && Buttons && layoutVersion === 'V2') {
    component = (
      <TextWithButtons
        title={Title}
        subtitle={Subtitle}
        content={Content}
        buttons={Buttons}
      />
    );
  }

  if (Quote_Tabs) {
    component = (
      <TabbedQuotes
        title={Title}
        subtitle={Subtitle}
        tabs={Quote_Tabs.map((t: QuoteTabDataSource) => ({
          title: t.Tab_Title,
          quotes: t.Quotes.map((quote: QuoteDataSource) => ({
            content: quote.Content,
            experience: quote.Experience,
            person: quote.Person,
          })),
        }))}
      />
    );
  }

  if (Icons) {
    component = (
      <TextWithIcons
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        icons={Icons.map((icon: IconDataSource) => ({
          content: icon.Content,
          url: icon.Key_Visual?.publicURL,
        }))}
        iconLayout={IconLayout}
        button={Button}
      />
    );
  }

  if (Items && ImageLayout) {
    component = (
      <FourUsp
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        items={Items.map((item: ContentItemDataSource) =>
          contentItemMapper(item)
        )}
        button={Button}
        imageLayout={ImageLayout}
      />
    );
  }

  if (Product_One && products && products.length > 0) {
    component = (
      <ProductGroup
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        item1={productDataMapper(Product_One, products)}
        item2={productDataMapper(Product_Two, products)}
      />
    );
  }

  if (Restriction) {
    component = (
      <RestrictedContent
        title={Title}
        subtitle={Subtitle}
        content={Content}
        location={Restriction}
      />
    );
  }

  if (Items && !ImageLayout) {
    component = (
      <ThreeUsp
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        items={Items.map((item: ContentItemDataSource) =>
          contentItemMapper(item)
        )}
      />
    );
  }

  if (Items && ContentLayout === 'Stacked') {
    component = (
      <Stacked
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        items={Items.map((item: ContentItemDataSource) =>
          contentItemMapper(item)
        )}
      />
    );
  }

  if (Items && ContentLayout === 'Alternate_Highlights') {
    component = (
      <AlternateHighlights
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        items={Items.map((item: ContentItemDataSource) =>
          contentItemMapper(item)
        )}
      />
    );
  }

  if (JobRoles) {
    const titles = JobRoles.map((r: JobRoleDataSource) => r.Title);
    component = (
      <JobRolesModule
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        roles={
          roles ? roles?.filter((r) => titles.indexOf(r.Title) !== -1) : []
        }
        button={Button}
      />
    );
  }

  if (Grid_Items) {
    component = (
      <Grid
        title={Title}
        subtitle={Subtitle}
        items={Grid_Items.map((item: FaqDataSource) => ({
          answer: item.Answer,
          question: item.Question,
        }))}
      />
    );
  }

  if (YouTube_URL) {
    component = (
      <TextAndVideo
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        url={YouTube_URL}
        button={Button}
      />
    );
  }

  if (Steps && layoutVersion === 'V2') {
    component = (
      <StepsModule
        title={Title}
        subtitle={Subtitle}
        steps={Steps.map((s: StepsDataSource) => ({
          title: s.Title,
          subtitle: s.Subtitle,
          content: s.Content,
        }))}
        button={Button}
      />
    );
  }

  if (ImageLayout && Colour) {
    component = (
      <ColourAdBlock
        title={Title}
        subtitle={Subtitle}
        content={Content}
        button={Button}
        color={Colour}
        image={Key_Visual}
        imageLayout={ImageLayout}
      />
    );
  }

  if (Questions && layoutVersion === 'V2') {
    component = (
      <FAQs
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        questions={Questions.map((i: FaqDataSource) => ({
          question: i.Question,
          answer: i.Answer,
        }))}
      />
    );
  }

  if (Quotes && layoutVersion === 'V2') {
    component = (
      <QuotesList
        title={Title}
        subtitle={Subtitle}
        quotes={Quotes.map((quote: QuoteDataSource) => ({
          content: quote.Content,
          experience: quote.Experience,
          person: quote.Person,
        }))}
      />
    );
  }

  if (Item1 && !TextQuotes) {
    component = (
      <SideBySide
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        item1={contentItemMapper(Item1)}
        item2={contentItemMapper(Item2)}
        contentDisplay={SideBySideDisplay}
        button={{
          text: Button?.Text,
          url: Button?.URL,
        }}
      />
    );
  }

  if (Item1 && TextQuotes) {
    component = (
      <TextAndQuote
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        item={contentItemMapper(Item1)}
        quotes={TextQuotes}
        variant={ColorVariant}
      />
    );
  }

  if (Blogs) {
    if (Layout === 'V2') {
      component = (
        <BlogsListV2
          title={Title}
          subtitle={Subtitle}
          introduction={Introduction}
          blogs={blogsItemMapper(Blogs, categories || [])}
          cta={Button?.Text}
          url={Button?.URL}
        />
      );
    } else {
      component = (
        <BlogsList
          title={Title}
          subtitle={Subtitle}
          introduction={Introduction}
          blogs={blogsItemMapper(Blogs, categories || [])}
          cta={Button?.Text}
          url={Button?.URL}
        />
      );
    }
  }

  if (ButtonText) {
    component = (
      <CTA
        title={Title}
        subtitle={Subtitle}
        url={URL}
        cta={ButtonText}
        image={Key_Visual}
      />
    );
  }

  if (Locations) {
    component = (
      <FiveImage
        title={Title}
        subtitle={Subtitle}
        introduction={Introduction}
        locations={Locations.map(locationMapper)}
      />
    );
  }

  if (Travel_With_Confidence_Title) {
    component = <TravelConfidence />;
  }

  if ((ReviewsTitle || ReviewsSubtitle) && layoutVersion === 'V2') {
    component = <Reviews title={ReviewsTitle} subtitle={ReviewsSubtitle} />;
  }

  if (Redirect_Item && layoutVersion === 'V2') {
    component = (
      <RedirectContent
        title={Redirect_Title}
        content={Redirect_Content}
        props={props}
        items={Redirect_Item.map((item: RedirectItemDataSource) =>
          redirectItemMapper(item)
        )}
      />
    );
  }

  if (Box_Item) {
    component = (
      <BoxContent
        items={Box_Item.map((item: BoxItemDataSource) => boxItemMapper(item))}
      />
    );
  }

  if (Banner_URL && Banner_Image && layoutVersion == 'V2') {
    component = <Banner url={Banner_URL} img={Banner_Image} />;
  }

  if (
    Icon &&
    SpotlightTitle &&
    TitleContent &&
    ContentDisplayImage &&
    layoutVersion === 'V2'
  ) {
    component = (
      <ContentDisplay
        title={TitleContent}
        titleNote={TitleNote}
        spotlightTitle={SpotlightTitle}
        description={Description}
        image={ContentDisplayImage}
        icon={Icon}
        props={props}
      />
    );
  }

  if (Brush && Brush.Stroke_Type) {
    component = (
      <BrushStroke brushType={Brush.Stroke_Type}>{component}</BrushStroke>
    );
  } 

  if (BackgroundType === 'Wood') {
    component = <Wood>{component}</Wood>;
  } else if (BackgroundType === 'Wood_Top') {
    component = <WoodTop>{component}</WoodTop>;
  } else if (BackgroundType === 'Wood_Bottom') {
    component = <WoodBottom>{component}</WoodBottom>;
  } else if (BackgroundType === 'Wood_Half') {
    component = <WoodHalf>{component}</WoodHalf>;
  } else if (BackgroundType === 'Paper') {
    component = <Paper>{component}</Paper>;
  } else if (BackgroundType === 'Paper_Top') {
    component = <PaperTop>{component}</PaperTop>;
  } else if (BackgroundType === 'Paper_Bottom') {
    component = <PaperBottom>{component}</PaperBottom>;
  } else if (BackgroundType === 'Paper_Half') {
    component = <PaperHalf>{component}</PaperHalf>;
  } else if (BackgroundType === 'LightPaper') {
    component = <PaperLight>{component}</PaperLight>;
  } else if (BackgroundType === 'LightPaper_Top') {
    component = <PaperLightTop>{component}</PaperLightTop>;
  } else if (BackgroundType === 'LightPaper_Bottom') {
    component = <PaperLightBottom>{component}</PaperLightBottom>;
  } else if (BackgroundType === 'LightPaper_Half') {
    component = <PaperLightHalf>{component}</PaperLightHalf>;
  } else if (!Galleries && !Steps && !Content && !Questions) {
    component = <Module>{component}</Module>;
  }

  return (
    <>
      {title}
      {component}
    </>
  );
};

export { Page };
export default Page;
