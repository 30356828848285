import React, { FunctionComponent } from 'react';
import { ContentItemTitle } from '@bunac/components/Programme/Modules/ContentItemTitle';
import { LinkButton } from '@bunac/components/Programme/LinkButton';
import { Blog } from '@bunac/components/Programme/Blog';
import { BlogItem } from '@bunac/components/Programme/Blogs/Item';
import { ContentCarousel } from '@bunac/components/Programme/ContentCarousel';
import { MarkdownRender } from '../MarkdownRender';

interface BlogsV2Props {
  title: string;
  subtitle: string;
  introduction?: string;
  blogs: Blog[];
  cta: string;
  url: string;
}

const BlogsV2: FunctionComponent<BlogsV2Props> = ({
  title = "From the",
  subtitle = "blog",
  introduction,
  blogs,
  cta = "Read more",
  url = "/blogs",
}) => (
  <div className="md:flex md:flex-col md:items-center text-center py-12">
    <h1 className='font-fave text-6xl font-bold text-navy'>{title} <span className='text-teal'>{subtitle}</span></h1>
    {introduction && (
      <div className="text-sm text-center mb-8">
        <MarkdownRender content={introduction} />
      </div>
    )}
    <img className='mx-auto'
      src="/images/highlight-underline-blue.svg"
      alt="Brush stroke dividing sections"
    />
    <div className="mb-8 mt-8 md:mt-0 md:hidden">
      <ContentCarousel>
        {blogs.map((blog, i) => (
          <BlogItem
            key={i}
            title={blog.title}
            introduction={blog.introduction}
            url={blog.url}
            image={blog.image}
            backgroundColor="grey-light"
          />
        ))}
      </ContentCarousel>
    </div>
    <div className="hidden md:px-4 md:grid md:grid-cols-3 md:gap-8 md:max-w-5xl md:mt-16 md:mb-0">
      {blogs.map((blog, i) => (
        <BlogItem
          key={i}
          title={blog.title}
          introduction={blog.introduction}
          url={blog.url}
          image={blog.image}
          backgroundColor="grey-light"
        />
      ))}
    </div>
    <div className="flex justify-center pb-8">
      <LinkButton cta={cta} url={url} color={'navy'} />
    </div>
  </div>
);

export { BlogsV2 };
